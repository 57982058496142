import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography, Popover } from "@material-ui/core";
import DoctorConsultBg from '../../assets/img/doctorConsultBg.png'
import {
  // LocationSearchResult,
  SearchBySpeciality,
  SearchHospitalResult,
  Location,
  ChoosememberSearchResult,
} from "../../components";
import {
  Container,
  Row,
  Div,
  Avatar,
  Text,
  Divider,
  Icons,
  BackgroundImage,
  Col,
  TextInput,
  Chip,
} from "qdm-component-library";
import { Routes } from "../../router/routes";
import "./index.css";
import { AlertProps, getImgUrl, getUserInfo, Logout,keySelection, UIColor } from "../../utils";
import Logo from "../../assets/img/Logo_W.svg";
import { AlertContext } from "../../contexts";
import { withStyles } from "@material-ui/core";
import { handleSymptomsData } from '../../components/searchBySpeciality';
import { handleSelectHospital } from '../../components/searchHospitalResult';

const styles = (theme) => ({
  textInput: {
    '& input': {
      fontFamily: 'pc_regular',
      // color:'#6F6F6F',
      // '&::placeholder':{
      //   color:'#E0E0E0',
      // }
    }
  },
});
class DoctorConsultation extends React.Component {
  constructor(props) {
    super(props);
    // state
    const { values } = this.props;
    this.state = {
      loggedInState: values?.selectedUser
        ? values?.selectedUser
        : {
          name: "",
          email: "",
          userId: 0,
          birthDay: "",
          gender: "",
        },
      doctor_consultation_cdlc2: this.props?.indexToShow === 2 ? true : null,
      symptoms: this.props?.indexToShow === 3 ? true : null,
      search_hospital: this.props?.indexToShow === 4 ? true : null,
      select_city: values ? values?.cityName : 0,
      select_city_name: values?.location || {
        address: "",
        latitude: 0,
        longitude: 0,
        city: "",
        loaction: "",
      },
      select_symptoms: values ? values?.symptoms : [],
      select_spccialities: values ? values?.specialities : [],
      select_doctors: values?.doctors ? values?.doctors : [],
      select_hospitsl: values ? values?.hospital : [],
      search_hospital_value: "",
      doctor_consultation_choosemember:
        this.props?.indexToShow === 1 ? true : null,
      symptomsSearch: "",
      select_symptomsSearch: values ? values?.symptomsSearch : [],
      symptomsDataState: [],
      allSymptomData: [],
      anchorEl: null,
      choosemember: [],
    };
    this.getUserInfo = getUserInfo.bind(this)
    // end
    this.node = React.createRef();
  }

  symptomsData = [
    {
      label: "Typhoid",
      value: "Typhoid",
      type: "Symptom",
    },
    {
      label: "Dengue Fever",
      value: "Dengue Fever",
      type: "Symptom",
    },
    {
      label: "Scarlet Fever",
      value: "Scarlet Fever",
      type: "Symptom",
    },
    {
      label: "Dr.Ramkumar K | General Medicine",
      value: "Dr.Ramkumar K | General Medicine",
      type: "Doctor",
    },
    {
      label: "Dr.Surendhar K | General Medicine",
      value: "Dr.Surendhar K | General Medicine",
      type: "Doctor",
    },
    {
      label: "Dr.Rajesh Kannan PS | General Medicine",
      value: "Dr.Rajesh Kannan PS | General Medicine",
      type: "Doctor",
    },
    {
      label: "Internal Medicine",
      value: "Internal Medicine",
      type: "Speciality",
    },
    {
      label: "General Physician",
      value: "General Physician",
      type: "Speciality",
    },
  ];

  changeState = (key, value) => {
    debugger
    this.setState({
      [key]: value,
    });
  };

  onclick_cdlc1 = (e, info) => {
    debugger
    if (info && info.name) {
      this.setState({
        doctor_consultation_choosemember:
          !this.state.doctor_consultation_choosemember,
        symptoms: null,
        search_hospital: null,
        doctor_consultation_cdlc2: null,
        loggedInState: info,
      });
    } else {
      this.setState({
        doctor_consultation_choosemember:
          !this.state.doctor_consultation_choosemember,
        symptoms: null,
        search_hospital: null,
        doctor_consultation_cdlc2: null,
      });
    }
  };

  onclick_cdlc2 = (e) => {
    this.setState({
      doctor_consultation_cdlc2: true,
      symptoms: null,
      search_hospital: null,
      doctor_consultation_choosemember: null,
    });
  };

  onclick_cdlc3 = (e) => {
    this.setState({
      symptoms: true,
      doctor_consultation_cdlc2: null,
      search_hospital: null,

      doctor_consultation_choosemember: null,
    });
  };

  onclick_cdlc4 = (e) => {
    this.setState({
      symptoms: null,
      doctor_consultation_cdlc2: null,
      search_hospital: !this.state.search_hospital,
      doctor_consultation_choosemember: null,
    });
  };

  onSeachClicked = (e) => {
    if (this.props.hideBackground) {
      if (this.state.select_hospitsl?.length > 0 && (!this.state?.select_doctors?.[0]?.value ? this.state.select_spccialities.length > 0 : true)) {
        const {
          select_hospitsl: hospital,
          select_spccialities: specialities,
          location,
          select_doctors
        } = this.state;
        this.props.onValueChange({
          hospital: hospital?.map(l => l?.value),
          specialities: specialities.map((spec) => spec.value),
          latitude: location?.latitude || 0,
          longtitude: location?.longitude || 0,
          doctorId: select_doctors?.[0]?.value
        });
        this.props.onHeaderClicked();
      } else {
        let mandFields = [];
        let errMsg = "Please fill the mandatory field";
        if (this.state.select_hospitsl?.length === 0) {
          mandFields.push("hospital");
        }
        if (this.state.select_doctors.length === 0 && this.state.select_spccialities.length === 0) {
          mandFields.push("specialty");
        }
        if (mandFields.length > 0) {
          errMsg = `Please choose a ${mandFields.join(' and ')}`
        }
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: errMsg,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      }
    }
    if (this.state.select_hospitsl?.length > 0 && (!this.state?.select_doctors?.[0]?.value ? this.state.select_spccialities.length > 0 : true)) {
      const {
        select_city,
        select_city_name,
        select_hospitsl,
        select_spccialities,
        select_symptoms,
        select_doctors,
        select_symptomsSearch,
      } = this.state;
      this.props.history.push({
        pathname: Routes.doctorListing,
        state: {
          cityName: select_city,
          location: select_city_name,
          hospital: select_hospitsl,
          specialities: select_spccialities,
          symptoms: select_symptoms,
          doctors: select_doctors,
          symptomsSearch: select_symptomsSearch,
          selectedUser: this.state.loggedInState,
        },
      });
    } else {
      let mandFields = [];
      let errMsg = "Please fill the mandatory field";
      if (this.state.select_hospitsl?.length === 0) {
        mandFields.push("hospital");
      }
      if (this.state.select_spccialities.length === 0) {
        mandFields.push("specialty");
      }
      if (mandFields.length > 0) {
        errMsg = `Please choose ${mandFields.join(' and ')}`
      }
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: errMsg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }
  };

  getchoosemember = async (userid, info) => {
    const user = [
      {
        name: [
          {
            given: info.name || "",
          },
        ],
        telecom: [
          {
            system: "Email",
            use: "Work",
            value: info.email || "",
          },
          {
            system: "Phone",
            value: info.mobile,
            use: "Mobile",
          }
        ],
        PersonID: userid,
        person: [
          {
            birthDay: info.birthDay,
            gender: info.gender,
            username: info.mobile || "",
            photo: [{
              fileid: info["fileid"],
            }],
          },
        ],
        photo: [{
          fileid: info["fileid"],
        }],
        birthDate: info.birthDay,
        gender: info.gender,
      },
    ];
    const payload = {
      userid,
    };
    if (this.props.GET_RELATED_PERSON) {
      if (userid) {
        const data = await this.props.GET_RELATED_PERSON(payload);
        if (data?.payload?.data) {
          this.setState({
            choosemember: [...user, ...data?.payload?.data],
          });
        }
      }
    }
  };
  componentDidMount() {
    this.setState({
      select_symptoms: this.props?.location?.state?.symptoms || []
    })
    this.getInfos();
    document.addEventListener("mousedown", this.handleClick);
  }

  componentWillUnmount() {
    document.addEventListener("mousedown", this.handleClick);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps?.indexToShow !== this.props.indexToShow) {
      this.setState({
        ...this.state,
        symptoms: nextProps?.indexToShow === 3 ? true : null,
        doctor_consultation_cdlc2: nextProps?.indexToShow === 2 ? true : null,
        search_hospital: nextProps?.indexToShow === 4 ? true : null,
        doctor_consultation_choosemember:
          nextProps?.indexToShow === 1 &&
            !(
              this.props?.guestInfo?.data?.type === 0 ||
              this.props?.guestInfo?.data?.type === 1
            )
            ? true
            : null,
      });
    }
  }

  //detect outside click
  handleClick = (e) => {

    if(this?.node?.current &&  this?.node?.current?.contains(document?.querySelector('.sideBarMenu'))){
          return false
    }
    debugger
    if (this.node && !this.node?.current?.contains(e.target)) {
      this.setState({
        symptoms: null,
        doctor_consultation_cdlc2: null,
        search_hospital: null,
        doctor_consultation_choosemember: null,
      });

      console.log("you just clicked outside of box");
    }
  };

  getInfos = async () => {
    const persondatainfo = await this.getUserInfo();
    const info = JSON.parse(JSON.stringify(persondatainfo));
    let personId = info.id;
    if (info.type === "token") {
      info["gender"] = info.persondata?.payload?.data?.gender;
      info["birthDay"] = info.persondata?.payload?.data?.birthDay;
      info["email"] = info.persondata?.payload?.data?.email || "";
      info["fileid"] = info.persondata?.payload?.data?.imgUrl;
      if (!(this.props.values && this.props.values?.selectedUser)) {
        info["userId"] = personId;
        this.changeState("loggedInState", {
          ...info,
        });
      }
    } else if (info.type === "guest") {
      await this.props.GUEST_INFO?.(info.info);
      this.changeState("loggedInState", {
        ...info,
      });
    }
    this.getchoosemember(personId, info);
    if (
      this.props.GET_CITIES &&
      this.props.GET_SYMPTOMS &&
      this.props.GET_ALL_SPECIALITY &&
      this.props.GET_SPECIALITY
    ) {
      const symptomArr = this.props.values?.symptoms?.length > 0 ? this.props.values?.symptoms?.map(s => s?.label) : []
      const city = this.props.GET_CITIES();
      const symptom = this.props.GET_SYMPTOMS();
      const speciality = symptomArr.length > 0 ? this.props.GET_SPECIALITY({ speciality: symptomArr }) : this.props.GET_ALL_SPECIALITY();
      const doctors = this.props.SPECIALITY_AGAINST_PRACTITIONER();
      const hospitals = this.GetAllHospitals(this.state?.select_city_name || {});
      this.state.select_doctors?.[0]?.value && this.GetAllHospitalsAgainstDoctor(this.state.select_doctors?.[0]?.value)
      const [, symptomsDatas, specialitiesData, hospitalData, doctorsData] = await Promise.all([
        city,
        symptom,
        speciality,
        hospitals,
        doctors
      ]);

      const searchData = [
        ...(symptomsDatas?.payload?.data || []),
        ...(specialitiesData?.payload?.data || []),
        ...(doctorsData?.payload?.data || []),
      ];
      this.changeState("allSymptomData", searchData);
      this.changeState("symptomsDataState", searchData);
    }
  };

  onChange = (v, s, x) => {
    this.onclick_cdlc2();
    this.setState({
      select_city_name: v,
      select_hospitsl: [],
      search_hospital_value: "",
    });
    this.GetAllHospitals(v);
  };

  GetAllHospitals = (location) => {
    if (typeof location === "object") {
      this.props.GET_HOSPITALS_LOCALITY({
        city: location.city,
        locality: location.location,
        location,
      });
    }
  };

  GetAllHospitalsAgainstDoctor = (id) => {
    this.props.PRACTITIONER_AGAINST_ORGANIZATION({
      practitionerId: id
    });
  };

  //  handleChange value for Doctor Consultation search
  handlesearchActives = (name, val) => {
    const state = this.state;
    let extra = {};
    if (name === "select_doctors") {
      extra = { select_hospitsl: [] }

    }
    this.setState({
      [name]: val,
      ...extra,
      doctor_consultation_cdlc2:
        name === "select_city_name" ? null : state.doctor_consultation_cdlc2,
      // search_hospital:
      //   name === "select_hospitsl" ? null : state.search_hospital,
      symptomsSearch:
        name === "select_symptomsSearch" ? "" : state.symptomsSearch,
      // search_hospital_value:
      //   name === "select_hospitsl" ? val.label : state.search_hospital_value,
    }, () => {
      if (name === "select_doctors") {
        this.GetAllHospitalsAgainstDoctor(val[0]?.value)
      }
    });
  };
  //   end
  //   handle delete value for Doctor Consultation Search by specialty symptoms disease treatment & doctor
  ondeleteSpecialtySymptomsDiseaseDoctor = (_c) => {
    const state = this.state;
    // eslint-disable-next-line
    const select_symptomsdata = state.select_symptoms.filter((l) => {
      if (l.value !== _c.value) return l;
    });
    // eslint-disable-next-line
    const select_spccialitiesdata = state.select_spccialities.filter((l) => {
      if (l.value !== _c.value) return l;
    });
    // eslint-disable-next-line
    const select_doctorsData = state.select_doctors.filter((l) => {
      if (l.value !== _c.value) return l;
    });

    const select_symptomsSearchData = state.select_symptomsSearch.filter(
      (l) => l !== _c.value
    );

    if (_c?.type === "Doctor") {
      this.GetAllHospitalsAgainstDoctor()
    }

    this.setState(
      {
        ...state,
        select_symptoms: select_symptomsdata,
        select_spccialities: select_spccialitiesdata,
        select_symptomsSearch: select_symptomsSearchData,
        select_doctors: select_doctorsData,
        select_hospitsl: _c?.type === "Doctor" ? [] : this.state?.select_hospitsl
      },
      () => {
        const arrVal = this.state?.select_symptoms?.map((a) => a.label) || [];
        if (this.props.GET_ALL_SPECIALITY && this.props.GET_SPECIALITY) {
          if (arrVal.length > 0) {
            this.props.GET_SPECIALITY({ speciality: arrVal }).then(spec => {
              const notSpecialities = (this?.state?.allSymptomData || []).filter(val => {
                return val.type !== "Speciality"
              });
              const newSpecialities = this?.state?.select_spccialities?.filter?.(d => {
                const index = spec?.payload?.data?.findIndex?.(dd => dd.value === d.value)
                return index !== -1
              });
              const newSymptoms = [...(spec?.payload?.data || []), ...notSpecialities];
              this.handlesearchActives('select_spccialities', [...newSpecialities]);
              this.changeState("allSymptomData", newSymptoms);
            });
          } else {
            this.handlesearchActives('select_spccialities', []);
            this.props.GET_ALL_SPECIALITY();
          }
        }
        this.onclick_cdlc3();
      }
    );
  };

  onDeleteHospital = (val) => {
    const filteredData = this.state?.select_hospitsl?.filter(l => l?.value !== val?.value);
    this.changeState('select_hospitsl', filteredData);
    this.onclick_cdlc4();
  }
  //   end

  //   handle delete all value for Doctor Consultation Search by specialty symptoms disease treatment & doctor
  ondeleteallSpecialtySymptomsDiseaseDoctor = () => {
    this.setState(
      {
        select_symptoms: [],
        select_spccialities: [],
        select_symptomsSearch: [],
        select_doctors: [],
        select_hospitsl: []
      },
      () => {
        if (this.props.GET_ALL_SPECIALITY) {
          this.props.GET_ALL_SPECIALITY();
        }
        if (this.props.PRACTITIONER_AGAINST_ORGANIZATION) {
          this.GetAllHospitalsAgainstDoctor();
        }
      }
    );
  };

  handleSymptomsData = (val) => {
    const filteredData = this.state.allSymptomData.filter((data) => {
      if (this.state.select_symptomsSearch?.indexOf(data.value) !== -1) {
        return false;
      }
      if (data?.label?.toLowerCase()?.includes(val?.toLowerCase() ?? "")) {
        return true;
      }
      return false;
    });
    // const filteredData = this.state.allSymptomData && this.state.allSymptomData?.filter(l=>this.state.select_symptomsSearch?.indexOf(l) === -1)?.filter(m=>m?.label?.toLowerCase()?.includes(val?.toLowerCase() ?? ''));
    this.setState({
      ...this.state,
      symptomsSearch: val,
      symptomsDataState: filteredData ?? [],
    });
  };

  handleProfileClick = (event) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
    });
  };

  handleProfileClose = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };


  onKeyDownSpeciality = (e,wrapper,selectionName,classlist) =>{
    if(this?.state?.symptomsSearch && this?.state?.symptomsDataState?.length > 0){
      this.keyDownWrapper(e,wrapper,selectionName,classlist,'specality')
    }
  }

  onKeyDownHospital = (e,wrapper,selectionName,classlist) =>{
    const hospitalSelection = this.state?.select_doctors?.[0]?.value ? this.props?.getAllHospitalsAgainstDoctor : this.props?.hospitals;
    if(hospitalSelection?.data?.filter(m => m?.label?.toLowerCase()?.includes(this.state?.search_hospital_value?.toLowerCase() ?? ''))?.length > 0){
      this.keyDownWrapper(e,wrapper,selectionName,classlist,'hospital')
    }
    
  }

  //up arrow and down arrow to select
  keyDownWrapper = (e,wrapper,selectionName,classlist,label) =>{
    let divWrap = document.getElementsByClassName(wrapper)[0];
    if(e.keyCode === 40){ //up arrow to select
      keySelection(divWrap,'next',selectionName,classlist)
    }
    else if(e.keyCode === 38){ //down arrow to select
      keySelection(divWrap,'previous',selectionName,classlist)
    }
    else if(e.keyCode === 13){ //enter to select
      let index = [...divWrap?.getElementsByClassName(selectionName)[0].parentElement.children].indexOf(divWrap?.getElementsByClassName(selectionName)[0]);
      if(label === "specality" && index > -1){
        handleSymptomsData(
          this.state.symptomsDataState[index],
          {
            ...this.props,
            values:this.state,
            changeState:this.changeState,
            handlesearchActives:this.handlesearchActives
          })
      }
      else if(label === "hospital" && index > -1){
        const hospitalSelection = this.state?.select_doctors?.[0]?.value ? this.props?.getAllHospitalsAgainstDoctor : this.props?.hospitals;
        let hospital = hospitalSelection?.data?.length > 0 && hospitalSelection?.data?.filter(m => m?.label?.toLowerCase()?.includes(this.state?.search_hospital_value?.toLowerCase() ?? ''))?.length > 0 && (
                       hospitalSelection?.data?.filter(m => m?.label?.toLowerCase()?.includes(this.state?.search_hospital_value?.toLowerCase() ?? '')))?.[index]
        
        handleSelectHospital(
          {
            label : hospital.name ? hospital.name : hospital.label,
            value : hospital._id ? hospital._id : hospital.value
          },
          {
            ...this.props,
            values:this.state,
            handlesearchActives:this.handlesearchActives
          }
          
        )
      }
    }
  }

  //   end

  render() {
    //   state declare
    const {
      select_city_name,
      select_spccialities,
      select_symptoms,
      select_symptomsSearch,
      select_doctors,
      select_hospitsl
    } = this.state;

    const { classes } = this.props;


    //   end
    const { name = "", img = "" } = (this.props.loggedUserInfo?.data || {});
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;
    const parent_ids = this.props.parent_id ? this.props.parent_id : 'doctor_consultation'
    return (
      <div
        id={`${parent_ids}doctor_consultation_parent_div`}
        style={{ width: "100%" }}>
        <Container
          id={`${parent_ids}_parent_container`}
          key={"0"}
          name="container"
          fluid={true}
          style={{
            backgroundColor: !this.props.hideBackground
              ? "#e8e8e8"
              : "transparent",
            height: this.props.hideBackground ? '100%' : "100vh",
            padding: "0px",
          }}
        >
          <Row
            id={`${parent_ids}_row_one`}
            key={"0"}
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            spacing="2"
            style={{
              backgroundColor: "#01205C",
              height: "131px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 32px",
            }}
          >
            <Div
              id={`${parent_ids}_header_logo_div`}
              key={"0"}
              inLineStyles="[object Object]"
              className="qdm"
              style={{
                display: "flex",
                alignSelf: "flex-start",
              }}
            >
              <img
                id={`${parent_ids}_header_logo_image`}
                key={"0"}
                variant="rounded"
                letter="Logo"
                src={Logo}
                alt="Logo"
                style={{
                  width: "100%",
                  height: "100%",
                }}
                className={"pc_regular"}
              />
            </Div>

            <Div
              id={`${parent_ids}_header_title_parent_div`}
              key={"1"}
              inLineStyles="[object Object]"
              className="qdm"
              style={{
                display: "flex",
                alignSelf: "flex-start",
                marginTop: "24px",
              }}
            >
              <Div
                id={`${parent_ids}_header_doctor_consultation_label__div`}
                key={"0"}
                inLineStyles="[object Object]"
                className="qdm"
                style={{
                  display: "",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "",
                }}
              >
                <Text
                  id={`${parent_ids}_header_doctor_consultation_label__text`}
                  className="pc_semibold"
                  key={"0"}
                  name="Doctor Consultation"
                  style={{
                    color: "#ffffff",
                    fontSize: "16px",
                  }}
                ></Text>

                <Divider
                  id={`${parent_ids}_header_doctor_consultation_label__text_bottom_divider`}
                  key={"1"}
                  className=""
                  label=""
                  borderColor=""
                  textColor=""
                  orientation="horizontal"
                  variant="middle"
                  style={{
                    width: "40px",
                    height: "3px",
                    borderColor: "#ffffff",
                    backgroundColor: "#ffffff",
                    margin: "4px auto",
                  }}
                ></Divider>
              </Div>
            </Div>

            <Div
              id={`${parent_ids}_header_guest_avatar_div`}
              key={"2"}
              inLineStyles="[object Object]"
              className="qdm"
              style={{
                display: "flex",
                alignSelf: "flex-start",
                marginTop: "10px",
                alignItems: "center",
              }}
            >
              <div
                id={`${parent_ids}_header_guest_sub_div_div`}
              >
                <Text
                  id={`${parent_ids}_header_guest_guest_name_text`}
                  className="pc_regular"
                  key={"0"}
                  name={name || "Guest"}
                  style={{ color: "#FFFFFF", fontSize: 14 }}
                ></Text>
                <br />
                <Text
                  id={`${parent_ids}_header_guest_version_text`}
                  className="pc_regular"
                  key={"0"}
                  name={"version : " + localStorage.getItem("version")}
                  style={{ color: "#FFFFFF", fontSize: 12, marginTop: -2 }}
                ></Text>
              </div>

              <Avatar
                id={`${parent_ids}_header_guest_name_avatar`}
                key={"1"}
                variant="square"
                src={img || undefined}
                letter={
                  name ||
                  this.state?.loggedInState?.name ||
                  this.props?.guestInfo?.data?.name ||
                  "Guest"
                }
                alt="G"
                style={{
                  width: "40px",
                  height: "40px",
                  padding: "",
                  marginLeft: "16px",
                  marginRight: "10px",
                  backgroundColor: UIColor.lineBorderFill.color,
                  fontSize: "16px",
                  fontFamily: "pc_medium",
                  borderRadius: "8px"
                }}
              ></Avatar>

              <Icons
                id={`${parent_ids}_header_guest_name_icon`}
                key={"2"}
                fontIcon="angle-down"
                ariaHidden="true"
                className=""
                size={18}
                style={{ color: "#ffffff", cursor: "pointer" }}
                onClick={(e) => this.handleProfileClick(e)}
              ></Icons>
            </Div>
          </Row>

          {
            <BackgroundImage
              id={`${parent_ids}_parent_background_image`}
              key={"1"}
              url={
                !this.props.hideBackground
                  ? DoctorConsultBg
                  : ""
              }
              backgroundPosition="0% 0%"
              backgroundPositionX="left"
              backgroundPositionY="top"
              backgroundRepeat="no-repeat"
              backgroundRepeatX="no-repeat"
              backgroundRepeatY="no-repeat"
              backgroundAttachment="scroll"
              backgroundSize="cover"
              backgroundOrigin="padding-box"
              inLineStyles="[object Object]"
              style={{
                height: this.props.hideBackground ? '100%' : "calc(100vh - 130px)",
                backgroundColor: "transparent",
                opacity: "100",
              }}
            >
              <Row
                id={`${parent_ids}_sub_header_row`}
                key={"0"}>
                <Col
                  id={`${parent_ids}_sub_header_col`}
                  key={"0"}>
                  <Div
                    id={`${parent_ids}_sub_header_parent_div`}
                    key={"0"}
                    inLineStyles="[object Object]"
                    className="qdm"
                    style={{
                      backgroundColor: "#F9F9F9",
                      height: "72px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      borderRadius: "36px",
                      marginBottom: "",
                      marginTop: "-30px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingRight: "8px",
                      width: "992px",
                    }}
                  >
                    <Div
                      id={`${parent_ids}_sub_header_guest_div`}
                      key={"0"}
                      inLineStyles="[object Object]"
                      className="qdm"
                      style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        paddingLeft: "8px",
                        paddingRight: "16px",
                        width: "160px",
                        cursor:
                          this.props?.loggedUserInfo?.data?.type === "token"
                            ? "pointer"
                            : (this.props?.guestInfo?.data?.type === 0 ||
                              this.props?.guestInfo?.data?.type === 1)
                              ? "auto"
                              : "pointer",
                        borderRadius: this.state
                          .doctor_consultation_choosemember
                          ? "100px"
                          : "unset",
                        backgroundColor: this.state
                          .doctor_consultation_choosemember
                          ? "#ffffff"
                          : "unset",
                        boxShadow: this.state.doctor_consultation_choosemember
                          ? "3px 3px 18px #0000001A"
                          : "unset",
                      }}
                      onClick={(e) =>
                        !(
                          this.props?.loggedUserInfo?.data?.type !== "token" &&
                          (this.props?.guestInfo?.data?.type === 0 ||
                          this.props?.guestInfo?.data?.type === 1)
                        ) && this.onclick_cdlc1(e)
                      }
                    >
                      <Avatar
                        id={`${parent_ids}_sub_header_guest_avatar`}
                        key={"0"}
                        variant="circle"
                        letter={
                          this.state?.loggedInState?.name ||
                          this.props?.guestInfo?.data?.name ||
                          ""
                        }
                        src={getImgUrl(this.state?.loggedInState?.fileid)}
                        alt="Image"
                        style={{
                          width: "32px",
                          height: "32px",
                          backgroundColor: "#E0E0E0",
                          fontSize: "14px",
                          fontFamily: "pc_medium",
                          color: "#101010",
                          marginLeft: "8px",
                        }}
                      ></Avatar>

                      <Text
                        id={`${parent_ids}_sub_header_guest_name_text`}
                        className="pc_regular"
                        key={"1"}
                        name={
                          this.state?.loggedInState?.name ||
                          this.props?.guestInfo?.data?.name ||
                          "Guest"
                        }
                        style={{
                          width: "90px",
                          color: "#101010",
                          fontSize: "14px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: 'noWrap',
                          letterSpacing: 0
                        }}
                      ></Text>
                    </Div>
                    {/* {this.state.doctor_consultation_choosemember ||
                      this.state.doctor_consultation_cdlc2 ? null : ( */}
                    <div
                      id={`${parent_ids}_sub_header_guest_rightside_divider_div`}
                      style={{
                        width: "1px",
                        height: "32px",
                        border: `1px solid ${(this.state.doctor_consultation_choosemember || this.state.doctor_consultation_cdlc2) ? 'transparent' : '#e0e0e0'}`,
                        display: "inline-block",
                      }}
                    ></div>
                    {/* )} */}
                    <Div
                      id={`${parent_ids}_sub_header_location_div`}
                      key={"1"}
                      inLineStyles="[object Object]"
                      className="qdm"
                      style={{
                        height: "100%",
                        borderRadius: this.state.doctor_consultation_cdlc2
                          ? "100px"
                          : "unset",
                        backgroundColor: this.state.doctor_consultation_cdlc2
                          ? "#ffffff"
                          : "unset",
                        boxShadow: this.state.doctor_consultation_cdlc2
                          ? "3px 3px 18px #0000001A"
                          : "unset",
                        marginTop: "",
                        paddingTop: "6px",
                        paddingRight: "16px",
                        paddingLeft: "16px",
                      }}
                      onClick={(e) => this.onclick_cdlc2(e)}
                    >
                      <Location
                        parent_id={'doctor_consultation'}
                        value={select_city_name}
                        label=""
                        active={this.state.doctor_consultation_cdlc2}
                        placeholder={"City / Locality"}
                        handleChange={(value) => this.onChange(value)}
                      // isReadonly={isReadyOnly}
                      // isrequired={isrequired}
                      />
                      {/* <TextInput
                      key={'0'}
                      type='text'
                      name='text'
                      variant='none'
                      helperTextType='error'
                      label=''
                      placeholder='City / Locality'
                      autoFocus={false}
                      autoComplete='off'
                      search={false}
                      borderColor='#9b9b9b'
                      value={select_city_name}
                      padding='10'
                      margin='0'
                      elevation='0'
                      style={{
                        borderWidth: '',
                        borderRadius: this.state.doctor_consultation_cdlc2
                          ? '24px'
                          : 'unset',
                        background: this.state.doctor_consultation_cdlc2
                          ? 'unset'
                          : 'transparent',
                        marginLeft: '8px'
                      }}
                    ></TextInput> */}
                    </Div>
                    {/* {this.state.doctor_consultation_cdlc2 ||
                      this.state.symptoms ? null : ( */}
                    <div
                      id={`${parent_ids}sub_header_location_field_rightside_divider_div`}
                      style={{
                        width: "1px",
                        height: "32px",
                        border: `1px solid ${(this.state.doctor_consultation_cdlc2 || this.state.symptoms) ? 'transparent' : '#e0e0e0'}`,
                        display: "inline-block",
                      }}
                    ></div>
                    {/* )} */}
                    <Div
                      id={`${parent_ids}_sub_header_search_by_specialty_div`}
                      key={"2"}
                      inLineStyles="[object Object]"
                      className="qdm"
                      style={{
                        height: "100%",
                        width: "440px",
                        borderRadius: this.state.symptoms ? "100px" : "unset",
                        backgroundColor: this.state.symptoms
                          ? "#ffffff"
                          : "unset",
                        boxShadow: this.state.symptoms
                          ? "3px 3px 18px #0000001A"
                          : "unset",
                        marginTop: "",
                        paddingTop: "",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        padding: "16px 16px",
                        overflow: "hidden",
                      }}
                    >
                      {/* search inside list out of  Search by specialty symptoms disease treatment & doctor data */}
                      <div
                        id={`${parent_ids}_sub_header_search_by_specialty_sub_div`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {[
                          ...select_symptoms,
                          ...select_spccialities,
                          ...select_doctors,
                          ...select_symptomsSearch,
                        ].length > 0 &&
                          [
                            ...select_symptoms,
                            ...select_spccialities,
                            ...select_doctors,
                            ...select_symptomsSearch,
                          ]
                            ?.filter((l, i) => i < 1)
                            ?.map((_c, i) => {
                              return (
                                <Chip
                                  id={`${parent_ids}_${i}_sub_header_search_by_specialty_chip`}
                                  className="pc_regular"
                                  onClick={() =>
                                    this.ondeleteSpecialtySymptomsDiseaseDoctor(
                                      _c
                                    )
                                  }
                                  key={i}
                                  variant="circle"
                                  name={_c?.label}
                                  style={{
                                    borderColor: "#fff",
                                    color: "#01205C",
                                    backgroundColor: "#F1F6FC",
                                    borderRadius: "8px",
                                    marginBottom: "0px",
                                    padding: '2px 12px',
                                    fontSize: 14
                                    // marginLeft: "16px"
                                  }}
                                  id={"Chip_" + i}
                                  closeIcon={
                                    <Icons
                                      id={`${parent_ids}_${i}_sub_header_search_by_specialty_icon`}
                                      key={"1"}
                                      fontIcon="close"
                                      ariaHidden="true"
                                      className=""
                                      size="small"
                                      style={{
                                        fontWeight: 100,
                                        "-webkitTextStroke": "1.4px white",
                                        transform:
                                          "scale(1) translate(0px, 0px)",
                                      }}
                                    ></Icons>
                                  }
                                ></Chip>
                              );
                            })}
                        {[...select_symptoms, ...select_spccialities, ...select_doctors]?.length >
                          0 &&
                          [...select_symptoms, ...select_spccialities, ...select_doctors]?.length >
                          1 && (
                            <div
                              id={`${parent_ids}_sub_header_search_by_specialty_chip_more_div`}
                              className="pc_regular"
                              style={{
                                color: "01205C",
                                marginRight: 7,
                                border: "1px solid #fff",
                                borderRadius: 8,
                                padding: "3px 5px",
                                minWidth: "80px",
                                textAlign: "center",
                                fontSize: 14,
                                backgroundColor: "#F1F6FC"
                              }}
                              id={"Chip_more"}
                            >
                              {"+"}
                              {[...select_symptoms, ...select_spccialities, ...select_doctors]
                                ?.length - 1}
                              {" More"}
                            </div>
                          )}
                      </div>
                      {/* end */}
                      <TextInput
                        id={`${parent_ids}_sub_header_search_by_specialty_label_textinput`}
                        onClick={(e) => this.onclick_cdlc3(e)}
                        key={"0"}
                        type="text"
                        name="text"
                        variant="none"
                        helperTextType="error"
                        label=""
                        placeholder={`Search by specialty symptoms & doctor`}
                        autoFocus={false}
                        autoComplete="off"
                        search={false}
                        borderColor="#9b9b9b"
                        padding="10"
                        margin="0"
                        elevation="0"
                        className={
                          this.state.symptoms ? "input_placeholder_color" : ""
                        }
                        value={this.state.symptomsSearch}
                        onChange={(e) =>
                          this.handleSymptomsData(e.target.value)
                        }
                        style={{
                          borderWidth: "",
                          borderRadius: this.state.symptoms ? "24px" : "unset",
                          background: this.state.symptoms
                            ? "unset"
                            : "transparent",
                          fontSize: "14px",
                          padding: "0px",
                          fontFamily: 'pc_regular',
                          color: '#101010'
                        }}
                        onKeyDown={e=>this.onKeyDownSpeciality(e,'search_symptoms_input','keySelection','search_symptoms_input_options')}
                      ></TextInput>
                    </Div>
                    {/* {this.state.symptoms ||
                      this.state.search_hospital ? null : ( */}
                    <div
                      id={`${parent_ids}_sub_header_search_by_specialty_rightside_divider_div`}
                      style={{
                        width: "1px",
                        height: "32px",
                        border: `1px solid ${(this.state.symptoms || this.state.search_hospital) ? 'transparent' : '#e0e0e0'}`,
                        display: "inline-block",
                      }}
                    ></div>
                    {/* )} */}
                    <Div
                      id={`${parent_ids}_sub_header_search_hospital_div`}
                      key={"3"}
                      onClick={(e) => this.onclick_cdlc4(e)}
                      inLineStyles="[object Object]"
                      className="qdm"
                      style={{
                        width: "172px",
                        height: "100%",
                        cursor: "pointer",
                        boxShadow: this.state.search_hospital
                          ? "3px 3px 18px #0000001A"
                          : "unset",
                        borderRadius: this.state.search_hospital
                          ? "50px"
                          : "unset",
                        backgroundColor: this.state.search_hospital
                          ? "#ffffff"
                          : "unset",
                        marginTop: "",
                        paddingTop: "",
                        paddingLeft: "16px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        paddingRight: "16px",
                      }}
                    >
                      <div
                        id={`${parent_ids}_sub_header_search_by_hospital_sub_div`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {select_hospitsl.length === 1 &&
                          <Chip
                            id={`${parent_ids}_sub_header_search_by_specialty_chip1`}
                            className="pc_medium"
                            onClick={() =>
                              this.onDeleteHospital(select_hospitsl?.[0])
                            }
                            variant="circle"
                            name={<p style={{
                              width: 27,
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              margin: 0
                            }}>{select_hospitsl?.[0]?.label}</p>}
                            style={{
                              borderColor: "#fff",
                              color: "#01205C",
                              backgroundColor: "#F1F6FC",
                              borderRadius: "8px",
                              marginBottom: "0px",
                              padding: '3px 12px'
                              // marginLeft: "16px"
                            }}
                            id={"Chip_hosp"}
                            closeIcon={
                              <Icons
                                id={`${parent_ids}_sub_header_search_by_specialty_icon`}
                                key={"1"}
                                fontIcon="close"
                                ariaHidden="true"
                                className=""
                                size="small"
                                style={{
                                  fontWeight: 100,
                                  "-webkitTextStroke": "1.4px white",
                                  transform:
                                    "scale(1) translate(0px, 0px)",
                                }}
                              ></Icons>
                            }
                          ></Chip>
                        }
                        {select_hospitsl?.length > 1 && (
                          <div
                            id={`${parent_ids}_sub_header_search_by_hospital_chip_more_div`}
                            className="pc_medium"
                            style={{
                              color: "rgb(24, 144, 255)",
                              marginRight: 7,
                              border: "1px solid rgb(24, 144, 255)",
                              borderRadius: 8,
                              padding: "3px 5px",
                              minWidth: "80px",
                              textAlign: "center",
                              fontSize: 13,
                            }}
                            id={"Chip_more"}
                          >
                            {"+"}{select_hospitsl?.length - 1}{" More"}
                          </div>
                        )}
                      </div>
                      <TextInput
                        id={`${parent_ids}_sub_header_search_hospital_textinput`}
                        key={"0"}
                        type="text"
                        name="text"
                        variant="none"
                        helperTextType="error"
                        label=""
                        value={this.state.search_hospital_value}
                        onChange={(e) =>
                          this.changeState(
                            "search_hospital_value",
                            e.target.value
                          )
                        }
                        onKeyDown={e=>this.onKeyDownHospital(e,'hospital_listing','keySelection','hospital_listing_options')}
                        placeholder={`Search hospital`}
                        autoFocus={false}
                        autoComplete="off"
                        search={false}
                        borderColor="#9b9b9b"
                        padding="10"
                        margin="0"
                        elevation="0"
                        style={{
                          borderWidth: "",
                          borderRadius: this.state.symptoms ? "24px" : "unset",
                          background: this.state.symptoms
                            ? "unset"
                            : "transparent",
                          paddingLeft: 0,
                          fontSize: "14px",
                          fontFamily: "pc_regular",
                        }}
                        className={
                          this.state.search_hospital ? "input_placeholder_color" : ""
                        }
                      ></TextInput>
                    </Div>

                    <Div
                      id={`${parent_ids}_sub_header_search_hospital_search_div`}
                      key={"4"}>
                      <Avatar
                        id={`${parent_ids}_sub_header_search_hospital_search_avatar_div`}
                        key={"0"}
                        onClick={this.onSeachClicked}
                        variant="circle"
                        letter="S"
                        src="https://i.ibb.co/QD4fvNs/icons8-search.png"
                        alt="Image"
                        style={{
                          width: "50px",
                          height: "50px",
                          marginRight: "0px",
                          marginTop: "",
                          backgroundColor: "#EC6A49",
                          padding: "16px",
                          cursor: "pointer",
                        }}
                      ></Avatar>
                    </Div>
                  </Div>
                </Col>

                <Col
                  id={`${parent_ids}_search_hospital_search_col`}
                  style={{ marginTop: "16px" }}>
                  {this.state.doctor_consultation_choosemember && (
                    <ChoosememberSearchResult
                      parent_id={'doctor_consultation'}
                      selectedId={this.state.loggedInState.userId}
                      choosemember={this.state.choosemember}
                      changeState={this.changeState}
                      handlememberClick={this.onclick_cdlc1}
                      refs={this.node}
                      type={this.props?.guestInfo?.data?.type}
                    />
                  )}
                  {/* doctor_consultation */}
                  {/* {this.state.doctor_consultation_cdlc2 &&
                    // !this.props.cities?.loading && (
                      <LocationSearchResult
                        handlesearchActives={this.handlesearchActives}
                        values={this.state}
                      />
                    // )
                    } */}
                  {/* end */}

                  {/* symptoms */}
                  {this.state.symptoms && (
                    <SearchBySpeciality
                      parent_id={'doctor_consultation'}
                      handlesearchActives={this.handlesearchActives}
                      ondeleteSpecialtySymptomsDiseaseDoctor={
                        this.ondeleteSpecialtySymptomsDiseaseDoctor
                      }
                      ondeleteallSpecialtySymptomsDiseaseDoctor={
                        this.ondeleteallSpecialtySymptomsDiseaseDoctor
                      }
                      changeState={this.changeState}
                      values={this.state}
                      refs={this.node}
                    />
                  )}
                  {/* end */}

                  {/* search_hospital */}
                  {this.state.search_hospital && (
                    <SearchHospitalResult
                      parent_id={'doctor_consultation'}
                      values={this.state}
                      handlesearchActives={this.handlesearchActives}
                      refs={this.node}
                    />
                  )}
                  {/* end */}
                </Col>
              </Row>
            </BackgroundImage>
          }
        </Container>
        <Popover
          id={id}
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={() => this.handleProfileClose()}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Typography
            onClick={() => Logout()}
            style={{ padding: "10px", cursor: "pointer", color: UIColor.primaryText.color }}
          >
            Log Out
          </Typography>
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cities: state?.searhcApiSlice?.cities,
  hospitals: state?.searhcApiSlice?.hospitals,
  guestInfo: state?.authSlice?.guestInfo,
  relatedPerson: state?.personApiSlice?.getRelatedPerson,
  getAllHospitalsAgainstDoctor: state?.searhcApiSlice?.getPractitionerAgainstOrganization,
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
  guestUserInfo: state?.authSlice?.guestInfo,

});

DoctorConsultation.contextType = AlertContext;

export default withStyles(styles)(connect(
  mapStateToProps,
  actions
)(withRouter(DoctorConsultation)));
