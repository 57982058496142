import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Header,
  ReasonforAppointment,
  DoctorCardDetail,
  AppointmentInstructions,
  BillSummary,
  DoctorAndAppointmentSlot,
  ReportAndAdditionalServices,
  FilterIcon,
  DobAge,
} from "../../components";
import {
  Container,
  Row,
  Col,
  Link,
  Div,
  Button,
  SideMenu,
  TextInput,
  Text,
  Divider,
  TapSelect,
  MobileNumberWithCode,
  TermsAndConditions,
  OtpInput,
  H6,
  Overlay,
  Avatar,
  Popover,
} from "qdm-component-library";
import Popup from "@material-ui/core/Popover";

import { DoctorConsultation } from "..";
import Sidemenu1 from "./sidemenu1";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Reasonforappointment from "../../assets/img/reason_for_appointment.svg";
import Modify_appointment from "../../assets/img/modify_appointment.svg";

import {
  AlertProps,
  checkError,
  checkName,
  fetchUserInfo,
  findAge,
  getImgUrl,
  getMobileNumber,
  getUserInfo,
  mobileNumberValidation,
  UIColor,
  upsertPerson,
  ValidateEmail,
} from "../../utils";
import moment from "moment";

import { Calendar } from "../../assets/img/calendar.js";
import FilterDrawer from "./filterDrawer";
import { Routes } from "../../router/routes";
import { AlertContext } from "../../contexts";
import "react-calendar/dist/Calendar.css";
import Calendars from "react-calendar";

import { filterListingData, uploadImage } from "../../utils";
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const styles = (theme) => ({
  expand: {
    transform: "rotate(0deg)",
    padding: 16,
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  body: {
    paddingTop: 0,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
  },
  label: {
    "& span": {
      fontSize: 12,
    },
    marginBottom: "16px",
  },
  guestTitle: {
    fontSize: 20,
    fontFamily: "pc_semibold",
    letterSpacing: 0,
    lineHeight: 1,
  },
  termsAndConditions: {
    "& .termsConditionsCheckbox": {
      borderRadius: 2,
    },
    "& .termsConditionsLabel": {
      flex: 1,
    },
  },
  monthCalendar: {
    "& strong": {
      fontSize: 13,
    },
    "& span": {
      fontSize: 13,
    },
    backgroundColor: "#fff",
  },
  clear: {
    backgroundColor: "transparent",
    border: "none",
    padding: "9px 12px",
    cursor: "pointer",
    color: UIColor.secondaryText.color,
    fontSize: 14,
  },
  apply: {
    backgroundColor: "transparent",
    border: "none",
    padding: "9px 12px",
    cursor: "pointer",
    color: UIColor.primaryColor.color,
    fontSize: 14,
  },
  divider: {
    minWidth: "0px",
    marginRight: "10px",
    marginLeft: "10px",
    color: UIColor.tertiaryText.color,
    marginBottom: 0,
    marginTop: 0,
  },
  earliestInput: {
    fontFamily: "pc_regular",
    fontSize: 14,
    "&::placeholder": {
      fontFamily: "pc_regular",
      fontSize: 14,
      color: UIColor.tertiaryText.color,
    },
  },
  textField: {
    "& input": {
      color: UIColor.primaryText.color,
      fontSize: "14px",
      //border: `1px solid ${UIColor.lineBorderFill.color}`,
      fontFamily: "pc_medium",
      borderRadius: 8,
    },
    "& input::placeholder": {
      color: UIColor.tertiaryText.color,
    },
  },
  tapSelect: {
    "& .selectionDiv": {
      height: 40,
      borderRadius: 8,
      minWidth: 40,
      margin: "8px 8px 0px 0px",
      borderColor: UIColor.lineBorderFill.color,
      "&:last-of-type": {
        marginRight: 0,
      },
      "& span": {
        fontFamily: "pc_regular",
        fontSize: 14,
      },
    },
    "& .active": {
      "& span": {
        fontFamily: "pc_medium !important",
      },
    },
  },
  mobile: {
    marginBottom: 16,
    "& .callingCodes,input": {
      fontSize: 14,
      color: UIColor.primaryText.color,
      fontFamily: "pc_medium",
      letterSpacing: "-0.34px",
      fontWeight: "normal",
    },
    "& input::placeholder": {
      fontFamily: "pc_regular",
    },
    // '& #addmember_form_mobilenumberwithcode':{
    //   borderColor: UIColor.lineBorderFill.color
    // },
    "& .mobileCountryDivider": {
      borderColor: UIColor.lineBorderFill.color,
    },
  },
});
class DoctorListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      added: false,
      oldSlot: {
        isUpdate: false,
        info: {},
        appInfoFetched: {},
      },
      fromListing: false,
      sidebar: false,
      collpaseOpen: 0,
      showOtpVerification: false,
      bulkBooking: false,
      city: this.props?.location?.state?.cityName || "",
      loaction: this.props?.location?.state?.loaction || "",
      hospital: this.props?.location?.state?.hospital || [],
      specialities: this.props?.location?.state?.specialities || [],
      symptoms: this.props?.location?.state?.symptoms || [],
      doctors: this.props?.location?.state?.doctors || [],
      symptomsSearch: this.props?.location?.state?.symptomsSearch || [],
      data: [],
      Tempdata: [],
      profileDetails: [],
      guestInfo: {
        name: this.props?.guestInfo?.data?.name || "",
        age: this.props?.guestInfo?.data?.age || "",
        gender: this.props?.guestInfo?.data?.gender || "",
        mobileNo: {},
        mailId: "",
        isMobile: false,
        isEmail: false,
      },
      guestBookerInfo: {
        name:
          this.props?.guestInfo?.data?.type === 0
            ? this.props?.guestInfo?.data?.name || ""
            : "",
        relationship:
          this.props?.guestInfo?.data?.type === 0
            ? { value: "Others", label: "Others" }
            : {},
        mobileNo: {},
        mailId: "",
        isMobile: false,
        isEmail: false,
      },
      otp: "0",
      addBookBy: true,
      selectedSlot: {},
      selectedSlotId: null,
      selectedSlotOrd: null,
      appointmentReason: [],
      modifyAppointmentReason: {},
      doctorInfo: "",
      indexToShow: null,
      age: { ...this.props?.guestInfo?.data?.age },
      rightSideAllCard: false,
      iagree: false,
      sideInfo: {
        age: 0,
        gender: "",
      },
      openCloseCalendar: false,
      filter: false,
      resetTimeSlot: null,
      consultationMode: {
        value: "Direct Consultation",
        label: "Direct Consultation",
      },
      paymentOption: {
        label: "Pay Later",
        value: "pay_later",
      },
      monthCalendarInputValue: "",
      error: {},
      appointmentUpdate: !!this.props?.location?.state?.modifyUser,
      isMultipleAppointment: false,
      multiApppointmentInfo: {
        appointments: {
          startsOn: {
            date: "",
            slotSelect: {},
            milli: 0,
          },
          preferrence: {
            preferredDays: [],
            preferredWeeks: [],
          },
          occurence: {
            occurrenceHandle: "NoOfDays",
            occurrenceValue: 1,
          },
          reccurance: {
            reccuranceValue: 1,
            reccuranceType: { label: "Days", value: "days" },
          },
        },
        info: {},
        data: [],
        slotObj: {},
        filterData: {},
        uploadFiles: [],
      },
      selectedDate: {
        unix: moment().startOf("date").unix(),
        val: moment().startOf("date").format("DD-MM-YYYY"),
      },
      allLang: [],
      meageget: {},
      // dropdownData: [
      //   {
      //     name: "India",
      //     flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
      //     altSpellings: "IND",
      //     callingCodes: "91",
      //   },
      // ],
      reasonModification: false,
      otpError: false,
      uploadFiles: [],
      patientInstruction: "",
      modalOpen: false,
      doctorName: "",
			doctorCount: 0
    };
    this.getUserInfo = getUserInfo.bind(this);
    this.fetchUserInfo = fetchUserInfo.bind(this);
    this.overlayNode = React.createRef();
  }

  handleModalOpen = async () => {
    debugger
    let personId_ = this.props?.location?.state?.selectedUser?.userId;
    if (!personId_) {
			let mobileNo = this.props?.location?.state?.selectedUser?.mobile;
			const persondata = await this.props.READ_PERSON({
				phone: mobileNo,
			});
			personId_ = persondata?.payload?.data?.id;
		}
    let start_ = this.state.selectedSlot?.info?.dateStart ?? 0;
    let res = await this.props.CHECK_APPOINTMENT_AGAINST_PATIENT({ patientId: personId_, start: start_ })
    if(res?.payload?.data?.Code === 201){
      if(Array.isArray(res?.payload?.data?.result) && res?.payload?.data?.result?.length > 0){
        if(res?.payload?.data?.result?.[0]?.allowToBookAppointment === false && res?.payload?.data?.result?.[0]?.doctorName?.length > 0){
          this.setState({
            ...this.state,
            modalOpen: true,
            doctorName: res?.payload?.data?.result?.[0]?.doctorName ?? "",
            doctorCount: res?.payload?.data?.result?.length 
          })
        }
      } else {
        this.handleBooking();
      }
    } else {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Something went wrong",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }
    debugger
  };

  handleModalClose = () => {
    this.setState({
      ...this.state,
      modalOpen: false
    })
  };

  resetMulti = () => {
    this.setState({
      ...this.state,
      isMultipleAppointment: false,
      multiApppointmentInfo: {
        appointments: {
          startsOn: {
            date: "",
            slotSelect: {},
            milli: 0,
          },
          preferrence: {
            preferredDays: [],
            preferredWeeks: [],
          },
          occurence: {
            occurrenceHandle: "NoOfDays",
            occurrenceValue: 1,
          },
          reccurance: {
            reccuranceValue: 1,
            reccuranceType: { label: "Days", value: "days" },
          },
        },
        info: {},
        data: [],
        slotObj: {},
        filterData: {},
        uploadFiles: [],
      },
    });
  };

  MultiBookingAppointmentLoopigUpdate = async (arr, index, resArr) => {
    let Data = [];
    let res = await this.props.APPOINTMENT_CREATE(arr[index]);
    Data.push(res);
    if (arr[index + 1]) {
      let resarr = await this.MultiBookingAppointmentLoopigUpdate(
        arr,
        index + 1
      );
      Data = [...Data, ...resarr];
    }
    return Data;
  };

  handleMultiAppointmentChange = (val, key1, key2, key3) => {
    const state = { ...this.state };
    if (key2) {
      if (key3) {
        state["multiApppointmentInfo"][key1][key2][key3] = val;
      } else {
        state["multiApppointmentInfo"][key1][key2] = val;
      }
    } else {
      state["multiApppointmentInfo"][key1] = val;
      if (key1 === "info") {
        state["multiApppointmentInfo"]["slotObj"] = {};
      }
    }
    this.setState({
      ...state,
    });
  };

  updateMultiSlots = (val) => {
    this.setState({
      ...this.state,
      fromListing: false,
      isMultipleAppointment: val,
    });
  };

  handleBooking = async () => {
    this.handleModalClose();
    if (this.props?.location?.state?.modifyUser && (!this.props?.location?.state?.newBook)) {
      if (!this.state.modifyAppointmentReason?.value) {
        this.setState({
          ...this.state,
          reasonModification: true,
        });
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: 'Please select reason for modification',
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        })
        return
      }
    }
    this.changeState("meageget", {});
    let UploadedFiles = [];
    let { uploadFiles } = this.state;
    UploadedFiles = await uploadImage(uploadFiles);
    if (this.state.selectedSlot?.info) {
      if (!!this.props?.location?.state?.modifyUser) {
        if (this.props?.location?.state?.newBook) {
          const userInfo = await this.getUserInfo();
          if (userInfo.type === "token") {
            if (this.state.iagree) {
              const {
                SpecialtyID = [],
                PersonID = [],
                practitionerID = [],
                AppointmentNotification,
                HealthcareServiceID,
              } = this.props?.location?.state?.appointMentInfo;
              const { info = {}, hospitalInfo = {} } = this.state.selectedSlot;
              const appointMentInput = {
                slotId: [info.value || 0],
                slotStart: info.dateStart || "",
                slotEnd: info.dateEnd || "",
                orgId: hospitalInfo?.org_Id,
                orgType: hospitalInfo?.orgType,
                resourcetype: info.resourcetype || "",
                resourcerole: info.resourcerole || "",
                resourcecode: info.resourcecode || "",
                info: this.state.appointmentReason,
                specialtyID: SpecialtyID,
                personID: PersonID,
                practitionerId: practitionerID,
                mobile: AppointmentNotification?.[0]?.mobile || "",
                email: AppointmentNotification?.[0]?.patientEmail || "",
                consultationMode: this.state.consultationMode?.value,
                healthCareId: HealthcareServiceID,
                report_docs: UploadedFiles,
                patientInstruction: this.state.patientInstruction,
              };
              const appInfo = await this.props.APPOINTMENT_CREATE(
                appointMentInput
              );
              const { isError, errMsg } = checkError(appInfo.payload);
              if (isError) {
                this.context.setSnack({
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: errMsg,
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.right,
                  tone: true,
                });
              } else {
                if(this.props?.location?.state?.isFollowUp){
										await this.props.SET_FOLLOWUP_SERVICE({
											data: {
												status: "booked",
												editId:this.props?.location?.state?.followUpId
											},
											});
									}
                const appointmentId =
                  appInfo.payload?.data?.appointment_response?.appointment
                    ?.Result?.[0]?.properties?.doc?.id || 0;
                this.setState({ ...this.state, sidebar: false }, () => {
                  // this.props.history.push("/confirm_booking");
                  this.props.history.push({
                    pathname: Routes.confirmBooking,
                    state: {
                      booker: this.state.guestBookerInfo,
                      patient: this.state.guestInfo,
                      appointmentId: appointmentId,
                      fromQueryPage: this.props?.location?.state?.fromQueryPage,
                      successUrl: this.props?.location?.state?.successUrl,
                      redirectUrl: this.props?.location?.state?.redirectUrl,
                    },
                  });
                });
              }
            }
          }
          return;
        }
        const appointmentData = await this.props.APPOINTMENT_READ({
          appointmentId: this.props.location?.state?.appointMentInfo?.id,
        });
        const appointMentInfo = JSON.parse(
          JSON.stringify(appointmentData?.payload?.data)
        );
        this.changeState("oldSlot", {
          ...this.state.oldSlot,
          appInfoFetched: appointMentInfo,
        });
        const status = checkError(appointmentData?.payload);
        if (status.isError) {
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: status.errMsg,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
        } else {
          const loggedInNumber = this.props.loggedUserInfo?.data?.mobile;
          await this.props.GENERATE_OTP({
            mobileNo: loggedInNumber,
          });
          const otpStatus = checkError(this.props.generateOTP);
          if (!otpStatus.isError) {
            this.setState({
              ...this.state,
              collpaseOpen: null,
              showOtpVerification: true,
              sidebar: true,
              otpNumber: loggedInNumber,
            });
          } else {
            this.context.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: otpStatus.errMsg,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
              tone: true,
            });
          }
        }
        return;
      }
      let userInfo;
      if (this.props?.location?.state?.fromQueryPage) {
        userInfo = await this.fetchUserInfo(
          localStorage.getItem("query_token")
        );
      } else {
        userInfo = await this.getUserInfo();
      }
      if (
        userInfo.type === "guest" &&
        this.props?.guestInfo?.isGuest &&
        this.state.guestInfo?.name
      ) {
        if (this.state.iagree) {
          this.toggleSideBar(true);
        }
      } else {
        if (userInfo.type === "token") {
          if (this.state.iagree) {
            let personId =
              this.props?.location?.state?.selectedUser?.userId || userInfo.id;
            const { info = {}, hospitalInfo = {} } = this.state.selectedSlot;
            if (this.state.isMultipleAppointment) {
              // const promArr = this.state.multiApppointmentInfo.data.map(
              //   (singleSlot) => {
              //     const appointMentInput = {
              //       slotId: [singleSlot.id || 0],
              //       slotStart: singleSlot.start || "",
              //       slotEnd: singleSlot.end || "",
              //       info: this.state.appointmentReason,
              //       specialtyID:
              //         this?.props?.location?.state?.specialities?.map(
              //           (spec) => spec?.value
              //         ) || [],
              //       personID: [personId],
              //       practitionerId: (
              //         hospitalInfo?.isClinic
              //           ? []
              //           : [hospitalInfo?.id]
              //       ),
              //       mobile:
              //         this.props.location?.state?.selectedUser?.mobile || userInfo.mobile || "",
              //       email:
              //         this.props.location?.state?.selectedUser?.email || userInfo.email || "",
              //       consultationMode: this.state.consultationMode.value,
              //       healthCareId: hospitalInfo?.isClinic ? [hospitalInfo?.clinicId]: info.healthCareId,
              //       report_docs: UploadedFiles,
              //     };
              //     return this.props.APPOINTMENT_CREATE(appointMentInput);
              //   }
              // );
              // const appResponse = await Promise.all(promArr);
              // const { isError, errMsg } = checkError(appResponse[0].payload);
              var appointMentInputArr = [];
              const promArr = this.state.multiApppointmentInfo.data.map(
                (singleSlot) => {
                  appointMentInputArr.push({
                    slotId: [singleSlot.id || 0],
                    slotStart: singleSlot.start || "",
                    slotEnd: singleSlot.end || "",
                    ...singleSlot.resourceInfo,
                    orgId: hospitalInfo?.org_Id,
                    orgType: hospitalInfo?.orgType,
                    info: this.state.appointmentReason,
                    specialtyID:
                      this?.props?.location?.state?.specialities?.map(
                        (spec) => spec?.value
                      ) || [],
                    personID: [personId],
                    practitionerId: hospitalInfo?.isClinic
                      ? []
                      : [hospitalInfo?.id],
                    mobile:
                      this.props.location?.state?.selectedUser?.mobile || "",
                    email:
                      this.props.location?.state?.selectedUser?.email || "",
                    consultationMode: this.state.consultationMode?.value,
                    healthCareId: info.healthCareId,
                    report_docs: UploadedFiles,
                    patientInstruction: this.state.patientInstruction,
                  });
                }
              );
              let resArr = [];
              const resMultiData =
                await this.MultiBookingAppointmentLoopigUpdate(
                  appointMentInputArr,
                  0,
                  resArr
                );
              const appResponse = await Promise.all(resMultiData);
              const { isError, errMsg } = checkError(appResponse?.[0]?.payload);
              if (isError) {
                this.context.setSnack({
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: errMsg,
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.right,
                  tone: true,
                });
              } else {
                this.setState({ ...this.state, sidebar: false }, () => {
                  // this.props.history.push("/confirm_booking");
                  this.props.history.push({
                    pathname: Routes.confirmBooking,
                    state: {
                      booker: this.state.guestBookerInfo,
                      patient: this.state.guestInfo,
                      appointmentInfo: appResponse,
                      isMulti: true,
                      fromQueryPage: this.props?.location?.state?.fromQueryPage,
                      successUrl: this.props?.location?.state?.successUrl,
                      redirectUrl: this.props?.location?.state?.redirectUrl,
                    },
                  });
                });
              }
            } else {
              // Normal Appointment Booking Case
              const appointMentInput = {
                slotId: [info.value || 0],
                slotStart: info.dateStart || "",
                slotEnd: info.dateEnd || "",
                resourcetype: info.resourcetype || "",
                resourcerole: info.resourcerole || "",
                resourcecode: info.resourcecode || "",
                orgId: hospitalInfo?.org_Id,
                orgType: hospitalInfo?.orgType,
                info: this.state.appointmentReason,
                specialtyID:
                  this?.props?.location?.state?.specialities?.map(
                    (spec) => spec?.value
                  ) || [],
                personID: [personId],
                practitionerId: hospitalInfo?.isClinic
                  ? []
                  : [hospitalInfo?.id],
                mobile:
                  this.props.location?.state?.selectedUser?.mobile ||
                  userInfo.mobile ||
                  "",
                email:
                  this.props.location?.state?.selectedUser?.email ||
                  userInfo.email ||
                  "",
                consultationMode: this.state.consultationMode?.value,
                healthCareId: hospitalInfo?.isClinic
                  ? [hospitalInfo?.clinicId]
                  : info.healthCareId,
                report_docs: UploadedFiles,
                patientInstruction: this.state.patientInstruction,
              };
              const appInfo = await this.props.APPOINTMENT_CREATE(
                appointMentInput
              );
              const { isError, errMsg } = checkError(appInfo.payload);
              if (isError) {
                this.context.setSnack({
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: errMsg,
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.right,
                  tone: true,
                });
              } else {
                const appointmentId =
                  appInfo.payload?.data?.appointment_response?.appointment
                    ?.Result?.[0]?.properties?.doc?.id || 0;
                this.setState({ ...this.state, sidebar: false }, () => {
                  // this.props.history.push("/confirm_booking");
                  this.props.history.push({
                    pathname: Routes.confirmBooking,
                    state: {
                      booker: this.state.guestBookerInfo,
                      patient: this.state.guestInfo,
                      appointmentId: appointmentId,
                      fromQueryPage: this.props?.location?.state?.fromQueryPage,
                      successUrl: this.props?.location?.state?.successUrl,
                      redirectUrl: this.props?.location?.state?.redirectUrl,
                    },
                  });
                });
              }
            }
          }
        } else if (userInfo.type === "guest") {
          if (this.state.iagree) {
            await this.props.GUEST_INFO?.(userInfo.info);
            console.log(this.props?.guestInfo);
            this.changeState("age", this.props?.guestInfo?.data?.age);
            this.changeState("guestInfo", {
              name: this.props?.guestInfo?.data?.name || "",
              age: this.props?.guestInfo?.data?.age || "",
              gender: this.props?.guestInfo?.data?.gender || "",
              mobileNo: {},
              mailId: "",
            });
            this.toggleSideBar(true);
          }
        }
      }
    }
  };

  changeGuestInfo = (type, field, val) => {
    const state = this.state;
    state[type][field] = val;
    this.setState(state);
  };

  changeState = (key, value) => {
    if(key === "data") {
      this.setState({
        Loading: false,
        [key]: value,
      });
    } else {
      this.setState({
        [key]: value,
      });
    }
  };

  clearMultipleAppointmentData = () => {
    debugger
    this.setState({
      ...this.state,
      multiApppointmentInfo: {
        appointments: {
          startsOn: {
            date: "",
            slotSelect: {},
            milli: 0,
          },
          preferrence: {
            preferredDays: [],
            preferredWeeks: [],
          },
          occurence: {
            occurrenceHandle: "NoOfDays",
            occurrenceValue: 1,
          },
          reccurance: {
            reccuranceValue: 1,
            reccuranceType: { label: "Days", value: "days" },
          },
        },
        info: {},
        data: [],
        slotObj: {},
        filterData: {},
        uploadFiles: [],
      }
    });
  };

  getDocterListingDetails = async ({
    hospital,
    specialities,
    latitude,
    longtitude,
    date,
    doctorId,
  }) => {
    const promArr = [
      this.props.DOCTER_LISTING({
        hospital: hospital,
        specialities,
        latitude,
        longtitude,
        date,
        doctorId,
      }),
      this.props.GET_ALL_LANGS(),
    ];
    await Promise.all(promArr);
    this.setState(
      {
        ...this.state,
        data: [],
        Tempdata: [],
      },
      () => {
        // const allLang = (this?.props?.docterListing?.allLangs || [])?.map?.(
        //   (d) => {
        //     return { title: d, val: d };
        //   }
        // );
        this.changeState("allLang", this?.props?.allLanguages?.data || []);
        this.changeState("data", this.props?.docterListing?.data || []);
        this.changeState("Tempdata", this.props?.docterListing?.data || []);
      }
    );
  };

  async componentDidMount() {
    await this.props.REASON_FOR_APPOINTMENT();
    await this.props.GET_COUNTRY_CODE_LIST();
    const { payload } = await this.props.GET_ALL_SPECIALITY_2();
    this.setState({ allSpeciality: payload.data });
    let userInfo = await this.getUserInfo();
    const info = {
      age: 0,
      gender: "",
    };
    if (!(userInfo && userInfo?.type)) {
      const queryToken = localStorage.getItem("query_token");
      if (queryToken && this.props.location?.state?.fromQueryPage) {
        userInfo = await this.fetchUserInfo(
          localStorage.getItem("query_token")
        );
        if (!(userInfo && userInfo?.type)) {
          window.location.href = Routes.signIn;
        }
      } else {
        window.location.href = Routes.signIn;
      }
    }
    if (userInfo.type === "token") {
      let mobileNo = userInfo.mobile;
      if (
        this.props.location?.state?.selectedUser &&
        this.props.location?.state?.selectedUser?.birthDay
      ) {
        info["age"] = moment().diff(
          moment(
            this.props.location?.state?.selectedUser?.birthDay,
            "DD/MM/YYYY"
          ),
          "years"
        );
        info["gender"] = this.props.location?.state?.selectedUser?.gender;
        this.changeState("sideInfo", info);
      } else {
        info["age"] = userInfo?.persondata?.payload?.data?.birthDay
          ? moment().diff(
            moment(
              userInfo?.persondata?.payload?.data?.birthDay,
              "DD/MM/YYYY"
            ),
            "years"
          )
          : "";
        info["gender"] = userInfo?.persondata?.payload?.data?.gender;
        this.changeState("sideInfo", info);
      }
    } else {
      info["age"] = moment().diff(
        moment(userInfo.info.age.val, "DD/MM/YYYY"),
        "years"
      );
      info["gender"] = userInfo.info.gender?.value || "";
      this.changeState("sideInfo", info);
    }
    if (
      this?.props?.location?.state &&
      !this.props.location?.state?.modifyUser
    ) {
      const {
        hospital = [],
        specialities = [],
        location = {},
        doctors = [],
        /*symptoms, location*/
      } = this?.props?.location?.state;
      // this.getDocterListingDetails({ hospital: hospital.value, specialities: [{ value: 1 }] })
      if (!doctors?.[0]?.value ? specialities.length > 0 : true) {
        this.getDocterListingDetails({
          hospital: hospital?.map((l) => l?.value),
          specialities: specialities.map((spec) => spec.value),
          latitude: location?.latitude || 0,
          longtitude: location?.longitude || 0,
          date: undefined,
          doctorId: doctors?.[0]?.value,
        });
      } else {
        window.location.href = Routes.doctorConsultation;
        return;
      }
    } else {
      this.appointmentRead();
      this.props.REASON_FOR_APPT_CANCEL_MODIFY({ type: 'APPMODIFYBYUSER' })
    }
    let value = {
      // countryData: this.state.dropdownData[0],
      countryData: this.props.countryList?.data,
      mobileNo: "",
    };
    if (this.state.dropdownData?.length === 1) {
      this.state.guestInfo.mobileNo = value;
      this.state.guestBookerInfo.mobileNo = value;
      this.setState({
        ...this.state,
      });
    }
  }

  handleClick = (e) => {
    if (this.overlayNode?.current?.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    this.setState({
      ...this.state,
      openCloseHeader: false,
      indexToShow: null,
    });
  };

  async appointmentRead() {
    const { Slots, resourcecode, slotID, resourcetype } =
      this.props.location?.state?.appointMentInfo;
    let start = Slots?.[0]?.start;
    if (this.props.location?.state?.newBook) {
      start = Math.floor(Date.now() / 1000);
    }
    const data = await this.props.APPOINTMENT_UPDATE_READ({
      start,
      id: [resourcecode?._id],
      slotID,
      HealthcareServiceID: [resourcecode?._id],
      type: resourcetype === "Practitioner" ? "practionerid" : "clinicid",
    });
    this.changeState("data", data?.payload?.data || []);
    const IsModifyUser = this.props?.location?.state?.modifyUser;
    const newBook = this.props?.location?.state?.newBook;
    if (IsModifyUser && !newBook) {
      const selected = this.props.reasonForAppointment?.data?.filter(d => {
        if (this.props?.location?.state?.appointMentInfo?.appointmentReason?.includes(d?.value)) {
          return true;
        }
        return false;
      });
      const uploadInfo = this.props?.location?.state?.appointMentInfo?.report_docs?.map(f => {
        return {
          name: f.fileName,
          type: f.filetype,
          fileUrl: getImgUrl(f.fileid),
          size: 0,
          originalData: f,
        }
      })
      this.changeState("uploadFiles", uploadInfo || []);
      this.changeState("appointmentReason", selected);
      this.changeState("patientInstruction", this.props?.location?.state?.appointMentInfo?.patientInstruction || "");
    }
  }

  shouldComponentUpdate(props, state) {
    return true;
  }

  toggleSideBar = (boolean) => {
    if (boolean) {
      this.setState({ ...this.state, sidebar: boolean });
    } else {
      this.setState({
        ...this.state,
        sidebar: boolean,
        showOtpVerification: false,
        collpaseOpen: 0,
      });
    }
  };

  handleOpen = async (index) => {
    if (index === 1) {
      // await this.props.REGISTER_USERS();
      // const { data } = this.props?.docterListingBookingFor
    }
    this.setState({
      ...this.state,
      collpaseOpen: index === this.state.collpaseOpen ? null : index,
    });
  };

  onHeaderClicked = (index) => {
    this.setState({
      openCloseHeader: !this.state.openCloseHeader,
      indexToShow: index ?? null,
    });
  };

  rightSideAllCardfun = (val, info) => {
    if (info && val) {
      this.setState({
        ...this.state,
        rightSideAllCard: val,
        DoctorAndAppointmentSlotCard: val,
        selectedSlot: info,
        resetTimeSlot: null,
        iagree: false,
        consultationMode: info.info.isdirect
          ? {
            value: "Direct Consultation",
            label: "Direct Consultation",
          }
          : info.info.isvideo
            ? {
              value: "Video Consultation",
              label: "Video Consultation",
            }
            : {},
      });
    } else {
      this.setState({
        ...this.state,
        rightSideAllCard: val,
        DoctorAndAppointmentSlotCard: val,
        resetTimeSlot: null,
        iagree: false,
        isMultipleAppointment: false,
        multiApppointmentInfo: {
          appointments: {
            startsOn: {
              date: "",
              slotSelect: {},
              milli: 0,
            },
            preferrence: {
              preferredDays: [],
              preferredWeeks: [],
            },
            occurence: {
              occurrenceHandle: "NoOfDays",
              occurrenceValue: 1,
            },
            reccurance: {
              reccuranceValue: 1,
              reccuranceType: { label: "Days", value: "days" },
            },
          },
          info: {},
          data: [],
          slotObj: {},
        },
      });
    }
  };

  genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Others", label: "Others" },
  ];

  relationshipOptions = [
    { value: "Father", label: "Father" },
    { value: "Mother", label: "Mother" },
    { value: "Brother", label: "Brother" },
    { value: "Sister", label: "Sister" },
    { value: "Others", label: "Others" },
  ];

  async goBack() {
    let mobileNumber = getMobileNumber(this.state?.guestInfo?.mobileNo);
    if (this?.props?.guestInfo?.data?.type === 1) {
      mobileNumber = getMobileNumber(this.state?.guestBookerInfo?.mobileNo);
    }
    await this.props.DELETE_USER({
      username: mobileNumber,
    });
    if (this.props?.guestInfo?.data?.type === 0) {
      this.setState({
        ...this.state,
        sidebar: true,
        showOtpVerification: false,
        collpaseOpen: 0,
      });
    } else {
      this.setState({
        ...this.state,
        showOtpVerification: false,
        collpaseOpen: 1,
      });
    }
  }

  handleMySelfSignUp() {
    // debugger
    const state = { ...this.state };
    state.guestBookerInfo.mobileNo = state.guestInfo?.mobileNo;
    state.guestBookerInfo.name = state.guestInfo?.name;
    state.guestBookerInfo.mailId = state.guestInfo?.mailId;
    state.guestBookerInfo.dob = state.guestInfo?.age.val;
    state.guestBookerInfo.isEmail = state.guestInfo?.isEmail;
    state.guestBookerInfo.isMobile = state.guestInfo?.isMobile;
    state.guestBookerInfo.gender = state.guestInfo?.gender.value;
    const check = this.checkValidationsForMyself(state.guestBookerInfo);
    debugger
    if (check) {
      this.setState({ ...state, error: {}, meageget: {} }, () => {
        this.showVerification(null, true);
      });
    }
  }

  checkValidationsForMyself = (guestBookerInfo) => {
    let {
      name = "",
      dob = "",
      gender = "",
      mobileNo = {},
      mailId = "",
      relationship = "",
    } = guestBookerInfo;
    if (typeof relationship === "object") {
      relationship = Object.keys(relationship)?.length === 0 && "";
    }
    if (
      name &&
      checkName(name) &&
      (this.props.guestInfo.data.type === 0
        ? dob && (this.checkAge(dob) === "" ? true : false)
        : true) &&
      (this.props.guestInfo.data.type === 0 ? (gender ? true : false) : true) &&
      // mobileNo?.mobileNo?.length > 9 &&
      // mobileNo?.countryData?.callingCodes &&
      (mailId ? ValidateEmail(mailId) : true) &&
      (this.props.guestInfo.data.type === 1
        ? relationship
          ? true
          : false
        : true)
    ) {
      let res__ = mobileNumberValidation(mobileNo?.mobileNo, mobileNo?.countryData?.callingCodes);
			debugger
			if (res__ === "Invalid Country code") {
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: res__,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        this.setState({
          ...this.state,
          error: {
            callingCodes: true
          }
        });
				return false;
			} else if (res__ === false) {
				this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please enter valid mobile number",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        this.setState({
          ...this.state,
          error: {
            mobileNo: true
          }
        });
				return false;
      } else{
        return true;
      }
    } else {
      this.setState({
        ...this.state,
        error: {
          guestBookerInfo: {
            //mobileNo: mobileNo?.mobileNo?.length > 9 ? false : true,
            dob:
              this.props.guestInfo.data.type === 0
                ? dob
                  ? false
                  : true
                : false,
            //callingCodes: mobileNo?.countryData?.callingCodes ? false : true,
            name: name?.length === 0 ? true : !checkName(name),
            gender:
              this.props.guestInfo.data.type === 0
                ? gender
                  ? false
                  : true
                : false,
            mailId: mailId ? (ValidateEmail(mailId) ? false : true) : false,
            relationship:
              this.props.guestInfo.data.type === 1
                ? relationship.length > 0
                  ? false
                  : true
                : false,
          },
        },
      });
      return false;
    }
  };

  checkMobileValidations = (mobileNo) => {
    if (mobileNo && Object.keys(mobileNo)?.length) {
      // if (
      //   mobileNo?.mobileNo?.length > 9 &&
      //   mobileNo?.countryData?.callingCodes
      // ) {
      //   return true;
      // } else {
      //   return false;
      // }
      let res__ = mobileNumberValidation(mobileNo?.mobileNo, mobileNo?.countryData?.callingCodes);
			debugger
			if (res__ === "Invalid Country code") {
				this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: res__,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
				return false;
			} else if (res__ === false) {
				this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please enter valid mobile number",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
				return false;
			} else {
        return true;
      } 
    } else {
      return false;
    }
  };

  checkValidationsForOthers = async () => {
    debugger
    const {
      name = "",
      age = {},
      gender = {},
      mailId = "",
      mobileNo = {},
    } = this.state.guestInfo;
    if (
      name &&
      checkName(name) &&
      age?.val &&
      this.checkAge(age?.val) === "" &&
      gender?.label &&
      (mailId ? ValidateEmail(mailId) : true)       
      // (mobileNo?.mobileNo ? mobileNo?.mobileNo?.length > 9 ? true : false : true) &&
      // (mobileNo?.countryData?.callingCodes ? mobileNo?.countryData?.callingCodes ? true : false : true)
    ) {
      debugger
      if(this.checkMobileValidations(mobileNo)){
      let personData = await this.checkUser(
        `+${mobileNo?.countryData?.callingCodes ?? "00"}${mobileNo.mobileNo}`
      );

      if (personData[0]) {
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: personData[1],
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        return;
      }
      this.setState({ ...this.state, error: { guestInfo: {} } }, () =>
        this.handleOpen(1)
      );
      } else {
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please enter valid mobile number",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
				return
      }
    } else {
      this.setState({
        ...this.state,
        error: {
          guestInfo: {
            name: name?.length === 0 ? true : !checkName(name),
            dob: age?.val?.length > 0 ? false : true,
            gender: gender?.label ? false : true,
            mailId: mailId ? (ValidateEmail(mailId) ? false : true) : false,
            // mobileNo: !mobileNo?.mobileNo
            //   ? true
            //   : mobileNo?.mobileNo?.length > 9 &&
            //     mobileNo?.countryData?.callingCodes
            //     ? false
            //     : mobileNo?.mobileNo || mobileNo?.countryData?.callingCodes
            //       ? mobileNo?.mobileNo?.length > 9
            //         ? false
            //         : true
            //       : false,
            mobileNo: mobileNumberValidation(mobileNo?.mobileNo, mobileNo?.countryData?.callingCodes),
            callingCodes:
              mobileNo?.countryData?.callingCodes && mobileNo?.mobileNo
                ? false
                : mobileNo?.countryData?.callingCodes || mobileNo?.mobileNo
                  ? mobileNo?.countryData?.callingCodes
                    ? false
                    : true
                  : false,
          },
        },
      });
      return false;
    }
  };

  sendOTPandConfirm = () => {
    let { guestBookerInfo, guestInfo } = this?.state;
    if (
      guestBookerInfo?.name &&
      guestInfo?.name &&
      guestBookerInfo?.name?.toLocaleLowerCase() ===
      guestInfo?.name?.toLocaleLowerCase()
    ) {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Same name should not be accepted",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      return false;
    }
    if (
      getMobileNumber(guestBookerInfo.mobileNo) === getMobileNumber(guestInfo.mobileNo)
    ) {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Same mobile number should not be accepted",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      return false;
    }
    debugger
    const check = this.checkValidationsForMyself(this.state.guestBookerInfo);
    if (check) {
      this.setState({
        ...this.state,
        error: { guestBookerInfo: {} },
        meageget: {},
      });
      this.showVerification(null, true);
    }
  };

  checkUser = async (mobileNo) => {
    let persondata = await this.props.READ_PERSON({
      phone: mobileNo,
    });
    const { isError, errMsg } = checkError(persondata?.payload);
    if (
      isError ||
      (persondata?.payload?.data?.id && persondata?.payload?.data?.id !== 0)
    ) {
      if (isError) {
        return [true, errMsg];
      }

      return [true, `User Already Exists for ${mobileNo}`];
    }
    return [false, ""];
  };

  showVerification = async (index, value) => {
    if (!index && value) {
      const { mobileNo, name, mailId, isEmail } = this.state.guestBookerInfo;
      let personData = await this.checkUser(
        `+${mobileNo?.countryData?.callingCodes ?? "00"}${mobileNo.mobileNo}`
      );

      if (personData[0]) {
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: personData[1],
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        return;
      }
      await this.props.REGISTER_USERS({
        mobileNo: `+${mobileNo?.countryData?.callingCodes ?? "00"}${mobileNo.mobileNo
          }`,
        firstName: name.split(" ")[0],
        lastName: name.split(" ")[1] || "",
        emailId: isEmail ? mailId : "",
      });
      const status = checkError(this.props.registerUsers);
      if (status.isError) {
        if (
          status.errMsg === "User already exists." &&
          this.props?.guestInfo?.data?.type !== 0
        ) {
          await this.props.GENERATE_OTP({
            mobileNo: `+${mobileNo?.countryData?.callingCodes ?? "00"}${mobileNo.mobileNo
              }`,
          });
          const otpStatus = checkError(this.props.generateOTP);
          if (!otpStatus.isError) {
            console.log("Add member only ...");
            this.setState({
              ...this.state,
              collpaseOpen: index,
              showOtpVerification: value,
              addBookBy: false,
            });
          } else {
            this.context.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: otpStatus.errMsg,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
              tone: true,
            });
          }
        } else {
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: status.errMsg,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
          console.error(status.errMsg);
        }
      } else {
        console.log("Add member and user only ...");
        this.setState({
          ...this.state,
          collpaseOpen: index,
          showOtpVerification: value,
        });
      }
    }
  };

  updateAppointment = async () => {
    let UploadedFiles = [];
    let { uploadFiles } = this.state;
    UploadedFiles = await uploadImage(uploadFiles);
    const appointMentInfo = this.state.oldSlot?.appInfoFetched;
    appointMentInfo["consultationMode"] = this.state.consultationMode?.value;
    appointMentInfo["slotID"] = [this.state.selectedSlot?.info?.value];
    appointMentInfo["start"] = this.state.selectedSlot?.info?.dateStart;
    appointMentInfo["end"] = this.state.selectedSlot?.info?.dateEnd;
    appointMentInfo["cancelationReason"] = [this.state.modifyAppointmentReason?._id] ?? [];
    appointMentInfo["requestedPeriod"] = [
      {
        start: this.state.selectedSlot?.info?.dateStart,
        end: this.state.selectedSlot?.info?.dateEnd,
      },
    ];
    appointMentInfo["oldStart"] =
      this.props?.location?.state?.appointMentInfo?.start || 0;
    appointMentInfo["oldEnd"] =
      this.props?.location?.state?.appointMentInfo?.start || 0;
    appointMentInfo.orgid = appointMentInfo['orgid']._id;
    appointMentInfo.orgtype = appointMentInfo.orgtype?._id;
    appointMentInfo.resourcecode = appointMentInfo.resourcecode._id;
    appointMentInfo.appointmentReason = this.state.appointmentReason?.map(i => i?.value);
    appointMentInfo.report_docs = UploadedFiles;
    appointMentInfo.patientInstruction = this.state.patientInstruction;
    const updatedData = await this.props.APPOINTMENT_UPDATE(appointMentInfo);
    const updateStatus = checkError(updatedData?.payload);
    if (updateStatus.isError) {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: updateStatus.errMsg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    } else {
      this.setState({ ...this.state, sidebar: false }, () => {
        const appointMentId =
          updatedData.payload?.data?.appointment_response?.appointment
            ?.Result?.[0]?.properties?.doc?.id || 0;
        // this.props.history.push("/confirm_booking");
        this.props.history.push({
          pathname: Routes.confirmBooking,
          state: {
            booker: this.state.guestBookerInfo,
            patient: this.state.guestInfo,
            appointmentId: appointMentId,
            modfied: true,
            fromQueryPage: this.props?.location?.state?.fromQueryPage,
            successUrl: this.props?.location?.state?.successUrl,
            redirectUrl: this.props?.location?.state?.redirectUrl,
          },
        });
      });
    }
  };

  changeScreen = async () => {
    debugger
    let UploadedFiles = [];
    let { uploadFiles } = this.state;
    UploadedFiles = await uploadImage(uploadFiles);
    const { mobileNo } = this.state.guestBookerInfo;
    let number = getMobileNumber(mobileNo);
    if (this.state.appointmentUpdate) {
      number = this.state.otpNumber;
    }
    await this.props.SIGN_IN({
      mobileNo: number,
      otp: this.state.otp,
    });
    const status = checkError(this.props.signIn);
    let appointmentId = 0;
    let bookerId = 0;
    if (status.isError) {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: status.errMsg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      this.changeState("otpError", true);

    } else {
      if (this.state.appointmentUpdate) {
        await this.updateAppointment();
        return;
      } else {
        if (this.props?.signIn?.data?.access_token) {
          localStorage.removeItem("guestInfo");
          localStorage.setItem(
            "token",
            btoa(JSON.stringify(this.props?.signIn?.data))
          );
        }
      }
      if (this.state.addBookBy) {
        const guestBookerAdd = {
          phone: getMobileNumber(this.state.guestBookerInfo?.mobileNo),
          dob: this.state.guestBookerInfo?.dob || "01/01/2000",
          firstName: this.state.guestBookerInfo?.name.split(" ")[0],
          lastName: this.state.guestBookerInfo?.name.split(" ")[1] || "",
          gender: this.state.guestBookerInfo?.gender || "Male",
          email: this.state.guestBookerInfo?.isEmail
            ? this.state.guestBookerInfo?.mailId || ""
            : "",
        };
        const {
          error,
          data: bookerData,
          getData,
        } = await upsertPerson(
          this.props.READ_PERSON,
          this.props.UPSERT_PERSON,
          guestBookerAdd
        );
        if (error && bookerData.includes("User Already Exists ")) {
          bookerId = getData.payload?.data?.id || 0;
        } else if (error) {
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: bookerData,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
          return;
        } else {
          bookerId = bookerData.payload?.data?.id || 0;
        }
      }
      const bookData = await this.props.READ_PERSON({
        phone: getMobileNumber(this.state.guestBookerInfo?.mobileNo),
      });
      if (this.props?.guestInfo?.data?.type === 0) {
        if (!this.state.addBookBy) {
          bookerId = bookData?.payload?.data?.id || 0;
          // localStorage.setItem("idval", bookerId);
        }
      } else {
        const guestInfoAdd = {
          phone: getMobileNumber(this.state.guestInfo?.mobileNo),
          dob: this.state.guestInfo?.age?.val,
          firstName: this.state.guestInfo?.name?.split(" ")[0],
          lastName: this.state.guestInfo?.name?.split(" ")[1] || "",
          gender: this.state.guestInfo?.gender?.value,
          email: this.state.guestInfo?.isEmail
            ? this.state.guestInfo?.mailId || ""
            : "",
        };
        const { error, data } = await upsertPerson(
          this.props.READ_PERSON,
          this.props.UPSERT_PERSON,
          guestInfoAdd
        );
        if (error) {
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: data,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
          return;
        } else {
          await this.props.ADD_RELATED_PERSON({
            ...guestInfoAdd,
            relationType: this.state.guestBookerInfo?.relationship?.value || "",
            relationPersonId: [bookData?.payload?.data?.id || 0],
            personId: data.payload?.data?.id || 0,
          });
          bookerId = data.payload?.data?.id || 0;
        }
      }
      const { info = {}, hospitalInfo = {} } = this.state.selectedSlot;
      this.setState({
        ...this.state,
        added: true,
      });
      // Guest Booking
      const appointMentInput = {
        slotId: [info.value || 0],
        slotStart: info.dateStart || "",
        slotEnd: info.dateEnd || "",
        resourcetype: info.resourcetype || "",
        resourcerole: info.resourcerole || "",
        resourcecode: info.resourcecode || "",
        orgId: hospitalInfo?.org_Id,
        orgType: hospitalInfo?.orgType,
        info: this.state.appointmentReason,
        specialtyID:
          this?.props?.location?.state?.specialities?.map(
            (spec) => spec?.value
          ) || [],
        personID: [bookerId || 0],
        practitionerId: hospitalInfo?.isClinic ? [] : [hospitalInfo?.id],
        mobile: getMobileNumber(this.state.guestInfo?.mobileNo),
        email: this.state.guestInfo?.isEmail
          ? this.state.guestInfo?.mailId
          : "",
        consultationMode: this.state.consultationMode?.value,
        healthCareId: hospitalInfo?.isClinic
          ? [hospitalInfo?.clinicId]
          : info.healthCareId,
        report_docs: UploadedFiles,
        patientInstruction: this.state.patientInstruction,
      };
      const appInfo = await this.props.APPOINTMENT_CREATE(appointMentInput);
      const { isError, errMsg } = checkError(appInfo.payload);
      if (isError) {
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: errMsg,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      } else {
        appointmentId =
          appInfo.payload?.data?.appointment_response?.appointment?.Result?.[0]
            ?.properties?.doc?.id || 0;
        this.setState({ ...this.state, sidebar: false }, () => {
          // this.props.history.push("/confirm_booking");
          this.props.history.push({
            pathname: Routes.confirmBooking,
            state: {
              booker: this.state.guestBookerInfo,
              patient: this.state.guestInfo,
              appointmentId: appointmentId,
              fromQueryPage: this.props?.location?.state?.fromQueryPage,
              successUrl: this.props?.location?.state?.successUrl,
              redirectUrl: this.props?.location?.state?.redirectUrl,
            },
          });
        });
      }
    }
  };

  handleOpenProfile = async () => {
    await this.props.DOCTER_LISTING_PROFILE();
    const { data } = this.props?.docterListingProfile;
    this.setState({
      profileDetails: data,
    });
  };
  handleresendOTP = async () => {
    let mobileNumber = getMobileNumber(this.state?.guestInfo?.mobileNo);
    if (this?.props?.guestInfo?.data?.type === 1) {
      mobileNumber = getMobileNumber(this.state?.guestBookerInfo?.mobileNo);
    }
    if (this.state.appointmentUpdate) {
      mobileNumber = this.state?.otpNumber;
    }
    if (mobileNumber) {
      this.changeState("otp", "");

      const meageget = await this.props.RESEND_OTP({
        mobileNo: mobileNumber,
      });
      this.changeState("meageget", meageget);
    } else {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Could not send otp. Please Try Again",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }
  };
  handlepapover = (e) => {
    if (this.state.opencalendar) {
      this.handlepapoverclose();
    } else {
      this.setState({ opencalendar: e.currentTarget });
    }
  };
  handlepapoverclose = (val) => {
    if (val) {
      this.setState({ opencalendar: null });
    }
  };
  filterCalendar = (e) => {
    this.setState({
      ...this.state,
      openCloseCalendar: e.currentTarget,
    });
  };
  handleClose = (val) => {
    this.setState({
      ...this.state,
      openCloseCalendar: val,
    });
  };
  mainfilter = () => {
    this.setState({
      ...this.state,
      filter: !this.state.filter,
    });
  };
  onKeyDown = (e) => {
    if (
      !(
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        e.keyCode === 8 ||
        e.keyCode === 46 ||
        e.keyCode === 37 ||
        e.keyCode === 39
      )
    ) {
      e.preventDefault();
    }
  };

  ApplyFilter = (filterData) => {
    this.changeState("data", []);
    const {
      consultmode,
      speciality,
      gender,
      lang,
    } = filterListingData(filterData);
    const {
      hospital = [],
      location = {},
      doctors = [],
      /*symptoms, location*/
    } = this?.props?.location?.state;
    this.props.DOCTER_LISTING({
      hospital: hospital?.map((l) => l?.value),
      specialities: speciality || [],
      latitude: location?.latitude || 0,
      longtitude: location?.longitude || 0,
      date: undefined,
      doctorId: doctors?.[0]?.value,
      consultmode,
      gender,
      lang,
    }).then(d => {
      this.changeState("data", d?.payload?.data || []);
      this.setState({
        ...this.state,
        filterData,
      }, () => this.mainfilter());
    });
  };

  uploadFun = (files) => {
    this.setState({
      uploadFiles: files,
    });
  };

  async handleApply() {
    let { monthCalendarInputValue, selectedDate } = this.state;
    const {
      hospital = [],
      specialities = [],
      location = {},
      doctors = [],
    } = this?.props?.location?.state;
    let date = moment(selectedDate.val, "DD-MM-YYYY");
    if (monthCalendarInputValue) {
      date = moment(
        new Date(
          moment(date, "MM-DD-YYYY").add(
            parseInt(monthCalendarInputValue),
            "days"
          )
        )
      ).format("MM-DD-YYYY");
    }
    this.setState(
      {
        applyedDate: date,
      },
      () => {
        this.getDocterListingDetails({
          hospital: hospital?.map((l) => l?.value),
          specialities: specialities.map((spec) => spec.value),
          latitude: location?.latitude || 0,
          longtitude: location?.longitude || 0,
          date: date,
          doctorId: doctors?.[0]?.value,
        });
        this.handleClose(false);
      }
    );
  }

  calculateAge = (val) => {
    let years = moment().diff(moment(val, "DD-MM-YYYY"), "years");
    let days = moment().diff(moment(val, "DD-MM-YYYY"), "days");
    return years < 200 && days > 0 ? findAge(this.state?.age?.val) : "";
  };

  checkAge = (val) => {
    let years = moment().diff(moment(val, "DD-MM-YYYY"), "years");
    let days = moment().diff(moment(val, "DD-MM-YYYY"), "days");
    if (!(years < 200 && days > 0)) {
      return "Enter Age should be less than 200 years";
    }
    return "";
  };

  render() {
    const info = {
      type: localStorage.getItem("token") ? "token" : "guest",
    };
    const { classes } = this.props;
    const { filterData, allSpeciality } = this.state;
    const open = Boolean(this.state.openCloseCalendar);
    const id = open ? "simple-popover" : undefined;
    const IsModifyUser = this.props?.location?.state?.modifyUser;
    const fromQueryPage = !!this.props?.location?.state?.fromQueryPage;
    const newBook = this.props?.location?.state?.newBook;
    return (
      <div id="docterListing_parent_div">
        <Container
          id="docterListing_parent_container"
          key={"0"}
          name="container"
          fluid={true}
          style={{
            backgroundColor: UIColor.greyBackground.color,
            padding: "0px",
            width: "100%",
          }}
        >
          <Row
            id="docterListing_parent_row_one"
            key={"0"}
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            spacing="2"
            style={{ backgroundColor: "", width: "100%" }}
          >
            <Overlay
              id="docterListing_header_overlay"
              open={this.state.openCloseHeader}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              // onClose={() => this.onHeaderClicked()}
              onClose={(e) => this.handleClick(e)}
            >
              <div ref={this.overlayNode} style={{ width: "100%" }}>
                <DoctorConsultation
                  parent_id={"doctorconsultation"}
                  hideBackground={true}
                  onValueChange={this.getDocterListingDetails}
                  onHeaderClicked={this.onHeaderClicked}
                  values={this.props?.location?.state || false}
                  indexToShow={this.state.indexToShow}
                />
              </div>
            </Overlay>
            <Header
              parent_id={"doctorlisting"}
              IsModifyUser={IsModifyUser || fromQueryPage}
              onHeaderClicked={this.onHeaderClicked}
              values={this.props?.location?.state}
            />
          </Row>
          <Row
            id="docterListing_content_parent_row"
            key={"1"}
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            spacing="2"
          >
            <Col
              id="docterListing_content_parent_col_one"
              key={"0"}
              name="qdm-col-12"
              alignItems="stretch"
              direction="row"
              justify="flex-start"
              textAlign="left"
              lg="8"
              xs="12"
              md="8"
              sm="12"
              style={{ padding: "0px", backgroundColor: UIColor.greyBackground.color }}
            >
              <Col
                id="docterListing_content_col_two"
                key={"0"}
                name="qdm-col-12"
                alignItems="stretch"
                direction="row"
                justify="space-between"
                textAlign="left"
                lg="12"
                xs="12"
                md="12"
                sm="12"
                style={{
                  padding: "16px 16px 16px 36px",
                  backgroundColor: UIColor.greyBackground.color,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Div
                  id="docterListing_content_doctor_available_div"
                // style={{ display: "flex", alignItems: "center" }}
                >
                  <Text
                    id="docterListing_content_doctor_available_span"
                    style={{ margin: "0px", fontSize: 16 }}
                    className={"pc_semibold"}
                  >
                    {IsModifyUser ? (
                      <Div id="docterListing_content_doctor_available_newbook_div">
                        <Text
                          id="docterListing_content_doctor_available_newbook_span"
                          style={{
                            margin: "0px",
                            fontSize: 16,
                            color: UIColor.primaryText.color
                          }}
                          className={"pc_semibold"}
                        >
                          {newBook ? "Book Again" : "Modify Appointment"}
                        </Text>
                        <br />
                        <span
                          id="docterListing_content_doctor_available_newbook_span"
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: UIColor.secondaryText.color
                          }}
                          className={"pc_regular"}
                        >
                          {newBook
                            ? "Book a new appointment."
                            : "If you like to change the schedule. You can change Here."}
                        </span>
                      </Div>
                    ) : (
                      "Doctors Available"
                    )}
                  </Text>

                  {!IsModifyUser && (
                    <Text
                      id="docterListing_content_doctor_available_data_span"
                      style={{
                        marginLeft: "8px",
                        marginTop: "2px",
                        fontSize: 16,
                        color: UIColor.secondaryText.color,
                      }}
                      className="pc_regular"
                    >
                      ({this.state.data?.length})
                    </Text>
                  )}
                </Div>

                {!IsModifyUser && (
                  <Div
                    id="docterListing_content_doctor_available_calender_div"
                    style={{
                      display: "flex",
                    }}
                  >
                    <Popup
                      id={id}
                      open={open}
                      anchorEl={this.state.openCloseCalendar}
                      onClose={() => this.handleClose(false)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      style={{ marginTop: "1%", marginLeft: "2%" }}
                    >
                      <div
                        id="docterListing_content_doctor_available_calender_div"
                        style={{
                          width: 300,
                          backgroundColor: UIColor.greyBackground.color,
                          // padding: "10px",
                        }}
                      >
                        {/* <Calendars 
                          id="docterListing_content_doctor_available_filter_calender_div_calendars"
                          minDate={new Date()}
                          value={new Date(this.state.selectedDate.unix * 1000)}
                          allowPartialRange
                          onClickMonth={(value, event) => {
                            this.handlepapoverclose(false);
                          }}
                          onClickYear={(value, event) => {
                            this.handlepapoverclose(false);
                          }}
                          onClickWeekNumber={(value, event) => {
                            this.handlepapoverclose(false);
                          }}
                          className={classes.monthCalendar}
                          onChange={(value, event) => {
                            console.log("initial value",value);
                            const date = {
                              val: moment(value).format("DD-MM-YYYY"),
                              unix: moment(value).startOf("date").unix(),
                            };
                            this.changeState("monthCalendarInputValue", "");
                            this.changeState("selectedDate", date);
                          }}
                        /> */}
                            <DatePicker
                              selected={new Date(this.state.selectedDate?.unix * 1000)}
                              minDate={new Date()}
                              onChange={(date) => {
                                if (date) {
                                  const dateSet = {
                                    val: moment(date).format("DD-MM-YYYY"),
                                    unix: moment(date).startOf("date").unix(),
                                  };
                                  this.changeState("selectedDate", dateSet);
                                } else {
                                  this.changeState("selectedDate", {
                                    unix: moment().startOf("date").unix(),
                                    val: moment().startOf("date").format("DD-MM-YYYY"),
                                  })
                                }
                              }}                              
                              inline
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              calendarStartDay={1}
                            />
                      </div>
                      <div
                        id="docterListing_content_doctor_available_filter_calender_div_bottom_div"
                        style={{
                          backgroundColor: UIColor.greyBackground.color,
                        }}
                      >
                        <Divider
                          variant="fullWidth"
                          textWidth={0}
                          label={"or"}
                          textColor={UIColor.tertiaryText.color}
                          borderColor={UIColor.tertiaryText.color}
                          className={classes.divider}
                        />
                      </div>
                      <div
                        id="docterListing_content_doctor_available_filter_calender_div_labels_div"
                        style={{
                          backgroundColor: UIColor.greyBackground.color,
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingTop: "3%",
                        }}
                      >
                        <div
                          id="docterListing_content_doctor_available_filter_calender_div_earliest_div"
                          style={{
                            backgroundColor: "#fff",
                            padding: "4px",
                            border: `1px solid ${UIColor.lineBorderFill.color}`,
                            borderRadius: 4,
                            width: "94%",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            height: "40px",
                          }}
                        >
                          <span
                            id="docterListing_content_doctor_available_filter_calender_div_earliest_label_span"
                            style={{ fontSize: 14 }}
                          >
                            Earliest available after
                          </span>{" "}
                          <input
                            id="docterListing_content_doctor_available_filter_calender_div_earliest_input"
                            style={{
                              width: 40,
                              height: 24,
                              border: `1px solid ${UIColor.lineBorderFill.color}`,
                              outline: "none",
                              borderRadius: 3,
                            }}
                            placeholder="000"
                            className={classes.earliestInput}
                            value={this.state.monthCalendarInputValue}
                            maxLength={3}
                            onChange={(e) => {
                              const date = {
                                val: moment().format("DD-MM-YYYY"),
                                unix: moment().startOf("date").unix(),
                              };
                              if (date.unix !== this.state.selectedDate?.unix) {
                                this.changeState("selectedDate", date);
                              }
                              this.changeState(
                                "monthCalendarInputValue",
                                e.target.value
                              );
                            }}
                            onKeyDown={(e) => this.onKeyDown(e)}
                          />{" "}
                          <span
                            id="docterListing_content_doctor_available_filter_calender_div_earliest_input_days_label_span"
                            style={{ fontSize: 14, fontWeight: 600 }}
                          >
                            Days
                          </span>
                        </div>
                      </div>
                      <div
                        id="docterListing_content_doctor_available_filter_calender_div_button_paret_div"
                        style={{ backgroundColor: UIColor.greyBackground.color }}
                      >
                        <div
                          id="docterListing_content_doctor_available_filter_calender_div_button_div"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <button
                            id="docterListing_content_doctor_available_filter_calender_div_clear_button"
                            className={classes.clear}
                            onClick={() => {
                              this.changeState("monthCalendarInputValue", "");
                            }}
                          >
                            Clear
                          </button>
                          <button
                            id="docterListing_content_doctor_available_filter_calender_div_apply_button"
                            className={classes.apply}
                            onClick={() => this.handleApply()}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </Popup>
                    <Div
                      id="docterListing_content_doctor_available_filter_calender_div"
                      style={{
                        backgroundColor: "#ffffff",
                        borderColor: "rgb(217 217 217)",
                        padding: "9px",
                        border: "1px solid rgb(217 217 217)",
                        borderRadius: "4px",
                        cursor: "pointer",
                        width: "32px",
                        height: "32px",
                        marginRight: 8,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={(e) => this.filterCalendar(e)}
                    >
                      <Calendar
                        id="docterListing_content_doctor_available_calender"
                        // color={false ? UIColor.primaryColor.color : UIColor.secondaryText.color}
                        color={UIColor.secondaryText.color}
                      />
                    </Div>
                    <Div
                      id="docterListing_content_doctor_available_mainfilter"
                      style={{
                        backgroundColor: "#ffffff",
                        borderColor: "rgb(217 217 217)",
                        padding: "9px",
                        border: "1px solid rgb(217 217 217)",
                        borderRadius: "4px",
                        cursor: "pointer",
                        width: "32px",
                        height: "32px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => this.mainfilter()}
                    >
                      <FilterIcon
                        id="docterListing_content_doctor_available_mainfilter_img"
                        color={UIColor.secondaryText.color}
                      />
                    </Div>
                  </Div>
                )}
              </Col>
              
              <Div
                id="docterListing_content_doctor_available_doctorcard_details_div"
                style={{
                  height: "calc(100vh - 148px)",
                  overflowY: "overlay",
                  padding: "0px 16px 0px 36px",
                }}
              >
                {(this.state.Loading || this.props?.docterListing?.loading) ? 
                  <div style={{display:"flex", justifyContent: "center"}}>
                    <CircularProgress />
                  </div>
                : 
                <>
                  {this.state.data?.length > 0
                ? 
                <>
                {this.state.data &&
                  this.state.data.map((d, key) => {
                    return !d ? null : (
                      <DoctorCardDetail
                        parent_id={"doctor_listing"}
                        handleChange={this.changeState}
                        resetMulti={this.resetMulti}
                        data={d}
                        keydata={key}
                        selectedDate={this.state.applyedDate}
                        selectedSlot={this.state.selectedSlotId}
                        selectedSlotId={this.state.selectedSlotId}
                        selectedSlotOrd={this.state.selectedSlotOrd}
                        oldAppointment={
                          this.props.location?.state?.appointMentInfo || {}
                        }
                        // orgId={this?.props?.location?.state?.hospital?.[0]?.value || 0}
                        values={this.state}
                        handleOpenProfile={this.handleOpenProfile}
                        rightSideAllCardfun={this.rightSideAllCardfun}
                        rightSideAllCard={this.state.rightSideAllCard}
                        totlenght={this.state.data?.length}

                      />
                    );
                  })}
                  </>
                :
                <div style={{
                  padding: "0px 16px 16px 36px",
                  fontWeight: 500
                }}>No result found</div>
                }
                </>
               }
              </Div>
              
            </Col>

            <Col
              id="docterListing_content_parent_col"
              key={"1"}
              name="qdm-col-12"
              alignItems="stretch"
              direction="row"
              justify="flex-start"
              textAlign="left"
              lg="4"
              xs="12"
              md="4"
              sm="12"
              style={{
                backgroundColor: UIColor.greyBackground.color,
                padding: "0px",
                // paddingBottom: "0",
              }}
            >
              <div style={{ paddingTop: "16px" }}>
                <Div
                  id="docterListing_content_parent_div"
                  style={{
                    height: "calc(100vh - 82px)",
                    overflowY: "auto",
                    padding: "0px 16px",
                  }}
                >
                  <Div
                    id="docterListing_content_parent_div"
                    style={{
                      padding: 10,
                      marginBottom: 8,
                      backgroundColor: "white",
                      boxShadow: "none",
                      border: `1.5px solid 4{UIColor.lineBorderFill.color}`,
                      borderRadius: 8,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      id="docterListing_content_avatar_location_fileid_image"
                      key={"0"}
                      variant="circle"
                      src={getImgUrl(
                        this.props.location?.state?.selectedUser?.fileid
                      )}
                      letter={this.props.location?.state?.selectedUser?.name}
                      alt="Image"
                      style={{
                        width: "32px",
                        height: "32px",
                        backgroundColor: UIColor.lineBorderFill.color,
                        fontSize: "14px",
                        color: UIColor.primaryText.color,
                        marginRight: "16px",
                        marginLeft: "0",
                        marginTop: "0",
                        marginBottom: "0",
                      }}
                      className="pc_medium"
                    ></Avatar>
                    <Text
                      id="docterListing_content_location_selectuser_text"
                      style={{ fontSize: 14, marginRight: 8, color: UIColor.primaryText.color }}
                      className={"pc_semibold"}
                    >
                      {this.props.location?.state?.selectedUser?.name}
                      {/* &nbsp; &nbsp; */}
                    </Text>
                    <span
                      id="docterListing_content_location_selectuser_span"
                      style={{
                        color: UIColor.tertiaryText.color,
                        marginBottom: "1%",
                        marginRight: 8,
                        color:  UIColor.tertiaryText.color,
                      }}
                    >
                      |
                    </span>{" "}
                    {/* &nbsp;&nbsp; */}
                    <Text
                      id="docterListing_content_age_text"
                      style={{ fontSize: 14, color: UIColor.tertiaryText.color }}
                      className={"pc_semibold"}
                    >
                      {this.state.sideInfo?.age || this.state.sideInfo?.age === 0
                        ? `${this.state.sideInfo?.age} Years, `
                        : ""}
                      {this.state.sideInfo?.gender}
                    </Text>
                  </Div>
                  <Div
                    id="docterListing_content_doctor_and_appointment_div"
                    style={{ marginBottom: "8px" }}
                  >
                    <DoctorAndAppointmentSlot
                      parent_id={"doctor_listing"}
                      loginType={info.type}
                      newBook={newBook}
                      fromListing={this.state.fromListing}
                      IsModifyUser={IsModifyUser}
                      name={this.state.selectedSlot?.hospitalInfo?.name || ""}
                      date={this.state.selectedSlot?.info?.label}
                      info={this.state.selectedSlot?.info}
                      hospitalInfo={this.state.selectedSlot?.hospitalInfo}
                      changeState={this.changeState}
                      handleMultiAppointmentChange={
                        this.handleMultiAppointmentChange
                      }
                      multiApppointmentInfo={this.state.multiApppointmentInfo}
                      isMultipleAppointment={this.state.isMultipleAppointment}
                      updateMultiSlots={this.updateMultiSlots}
                      oldSlotInfo={this.state.oldSlot}
                      consultationMode={this.state.consultationMode}
                      time={
                        this.state.selectedSlot?.info?.date
                          ? moment(
                            new Date(this.state.selectedSlot?.info?.date)
                          ).format("Do MMM YYYY")
                          : ""
                      }
                      handleCollapse={() =>
                        this.changeState(
                          "slotSelected",
                          !this.state.slotSelected
                        )
                      }
                      DoctorAndAppointmentSlotCard={this.state.rightSideAllCard}
                      rightSideAllCardfun={this.rightSideAllCardfun}
                      clearMultipleAppointmentDat={this.clearMultipleAppointmentData}
                    />
                  </Div>
                  {this.state.rightSideAllCard ? (
                    <>
                      <Div style={{ marginBottom: "8px" }}>
                        <ReasonforAppointment
                          parent_id={"doctor_listing"}
                          handleChange={(data) => this.changeState('appointmentReason', data)}
                          info={this.state.appointmentReason}
                          options={this.props.reasonForAppointment?.data ?? []}
                          title="Reason for Appointment"
                          label="Add your health issues here"
                          img={Reasonforappointment}
                          multiple
                        />
                      </Div>
                      <Div style={{ marginBottom: "8px" }}>
                        <ReportAndAdditionalServices
                          parent_id={"doctor_listing"}
                          uploadFun={this.uploadFun}
                          changeState={this.changeState}
                          patientInstruction={this.state.patientInstruction}
                          upload={this.state.uploadFiles}
                          isVideo={
                            this.state.consultationMode?.value?.toLowerCase?.() ===
                            "video consultation"
                          }
                          IsModifyUser={IsModifyUser}
                        />
                      </Div>
                      <Div style={{ marginBottom: "8px" }}>
                        <AppointmentInstructions
                          parent_id={"doctor_listing"}
                          iagree={this.state.iagree}
                          changeIAgree={() => {
                            this.setState({
                              ...this.state,
                              iagree: !this.state.iagree,
                            });
                          }}
                        />
                      </Div>
                      <Div style={{ marginBottom: "8px" }}>
                        <BillSummary
                          paymentOption={this.state.paymentOption}
                          changeState={this.changeState}
                          parent_id={"doctor_listing"}
                        />
                      </Div>
                      {
                        (IsModifyUser && !newBook) &&
                        <Div style={{ marginBottom: "8px" }}>
                          <ReasonforAppointment
                            parent_id={"doctor_listing"}
                            handleChange={(data) => {
                              this.setState({
                                ...this.state,
                                reasonModification: false
                              });
                              this.changeState('modifyAppointmentReason', data)
                            }}
                            info={this.state.modifyAppointmentReason}
                            options={this.props?.reasonForApptCancelModify.data ?? []}
                            title="Reason for Modification"
                            label="Choose your reason*"
                            img={Modify_appointment}
                            error={this.state.reasonModification}
                          />
                        </Div>
                      }

                      <>
                        {/* <Div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: "0px",
                            width: "100%",
                            marginTop: "6%",
                          }}
                          id='doctor_listing_iagreeinstructions_div'
                        >
                          <TermsAndConditions
                            value={this.state.iagree}
                            // key={"4"}
                            id='doctor_listing_iagreeinstructions_termsAndconditions_div'
                            lable={
                              <span
                                id='doctor_listing_iagreeinstructions_termsAndconditions_span'
                                style={{
                                  fontSize: "12px",
                                  color: UIColor.primaryText.color,
                                  marginBottom: "4px",
                                  marginTop: "-10px",
                                }}
                                className="pc_medium"
                              >
                                {"I agree that I've read the instructions "}{" "}
                                <span
                                  id='doctor_listing_iagreeinstructions_termsAndconditions_star'
                                  style={{ color: UIColor.error.color }}>*</span>
                              </span>
                            }
                            inLineStyles={{ marginBottom: 16 }}
                            className={classes.termsandcondition}
                            onChange={() =>
                              this.setState({
                                ...this.state,
                                iagree: !this.state.iagree,
                              })
                            }
                          />
                        </Div> */}
                        <Div
                          style={{ textAlign: "center" }}
                          id="doctor_listing_booking_button"
                        >
                          {!this.state.consultationMode?.value ? (
                            "Please Select the Consultation Mode"
                          ) : !this.state.paymentOption?.value ? (
                            "Please Select the Payment Mode"
                          ) : this.state.iagree ? (
                            <Button
                              name={
                                !!this.props?.upsertAppointment?.loading || !!this.props.generateOTP?.loading || !!this.props?.checkAppointmentAgainstPatient?.loading
                                  ? "Booking..."
                                  : "Confirm Booking"
                              }
                              onClick={this.handleModalOpen}
                              disabled={
                                !!this.props?.upsertAppointment?.loading || !!this.props?.checkAppointmentAgainstPatient?.loading
                              }
                              style={{
                                backgroundColor: UIColor.primaryColor.color,
                                borderColor: UIColor.primaryColor.color,
                                color: "#fff",
                                marginTop: "3%",
                                padding: "6px 16px",
                                borderRadius: "8px",
                                marginBottom: "4%",
                                fontSize: 14
                              }}
                              className="pc_medium"
                            />
                          ) : (
                            <Button
                              id="doctor_listing_booking_button"
                              name="Confirm Booking"
                              disabled={true}
                              style={{
                                backgroundColor: UIColor.lineBorderFill.color,
                                borderColor: UIColor.lineBorderFill.color,
                                color: UIColor.tertiaryText.color,
                                marginTop: "3%",
                                padding: "6px 16px",
                                borderRadius: "8px",
                                marginBottom: "4%",
                              }}
                              className="pc_medium"
                            />
                          )}
                        </Div>
                      </>
                    </>
                  ) : null}
                </Div>
              </div>
              <SideMenu
                id="doctor_listing_sidemenu"
                direction="right"
                width={332}
                color="default"
                onClose={() => this.mainfilter()}
                inLineStyles={{ padding: 15 }}
                open={this.state.filter}
              >
                {this.state.filter && (
                  <FilterDrawer
                    parent_id={"doctor_listing"}
                    allSpeciality={allSpeciality}
                    selectedSpec={this?.props?.location?.state?.specialities}
                    filterData={filterData}
                    ApplyFilter={this.ApplyFilter}
                    resetFilter={() => {
                      if (filterData && Object.keys(filterData).length > 0) {
                        this.changeState("data", []);
                        const {
                          hospital = [],
                          specialities = [],
                          location = {},
                          doctors = [],
                          /*symptoms, location*/
                        } = this?.props?.location?.state;
                        this.props.DOCTER_LISTING({
                          hospital: hospital?.map((l) => l?.value),
                          specialities: specialities.map((spec) => spec.value),
                          latitude: location?.latitude || 0,
                          longtitude: location?.longitude || 0,
                          date: undefined,
                          doctorId: doctors?.[0]?.value,
                        }).then(d => {
                          this.changeState("data", d?.payload?.data || []);
                          this.changeState("filterData", {})
                          this.changeState("applyedDate", moment().format("MM-DD-YYYY"))
                        });
                      } else {
                        this.context.setSnack({
                          open: true,
                          severity: AlertProps.severity.error,
                          msg: 'No Filter Applied',
                          vertical: AlertProps.vertical.top,
                          horizontal: AlertProps.horizontal.right,
                          tone: true,
                        })
                      }
                    }}
                    onClose={this.mainfilter}
                    allLang={this.state.allLang}
                  />
                )}
              </SideMenu>
              <SideMenu
                id="doctor_listing_sidemenu"
                open={this.state.sidebar}
                direction="right"
                width={435}
                color="default"
                onClose={() => {
                  if (
                    !this.state.added &&
                    this.state.showOtpVerification &&
                    !this.props?.location?.state?.modifyUser
                  ) {
                    let mobileNumber = getMobileNumber(
                      this.state?.guestInfo?.mobileNo
                    );
                    if (this?.props?.guestInfo?.data?.type === 1) {
                      mobileNumber = getMobileNumber(
                        this.state?.guestBookerInfo?.mobileNo
                      );
                    }
                    this.props.DELETE_USER({
                      username: mobileNumber,
                    });
                  }
                  this.toggleSideBar(false);
                }}
                inLineStyles={{ padding: "16px 24px" }}
              // open={true}
              >
                <text
                  id="doctor_listing_sidemenu_upadte"
                  className={classes.guestTitle}
                >
                  {this.state.appointmentUpdate
                    ? "Update Appointment"
                    : "Guest user confirmation"}
                </text>

                <Divider
                  id="doctor_listing_sidemenu_divider"
                  key={"2"}
                  className=""
                  label=""
                  borderColor=""
                  textColor=""
                  orientation="horizontal"
                  inLineStyles={{ margin: "16px 0px" }}
                // variant="middle"
                // style={{ height: "24px" }}
                />

                {this.state.showOtpVerification ? (
                  <Div id="doctor_listing_sidemenu_appointmentupadte_div">
                    {this.props?.guestInfo?.data?.type === 0 ? null : (
                      <Div
                        style={{
                          border: `1px solid ${UIColor.lineBorderFill.color}`,
                          marginBottom: 10,
                          borderRadius: 8,
                          padding: 16,
                          display: this.state.appointmentUpdate
                            ? "none"
                            : "block",
                        }}
                      >
                        <H6
                          id="doctor_listing_sidemenu_booking_label_h6"
                          inLineStyles={{
                            fontSize: 16,
                            color: UIColor.primaryText.color,
                            letterSpacing: 0,
                            lineHeight: 1,
                          }}
                          className="pc_medium"
                        >
                          BOOKING FOR
                          <Text
                            id="doctor_listing_sidemenu_booking_text"
                            inLineStyles={{
                              color: UIColor.secondaryColor.color,
                              fontSize: 16,
                              letterSpacing: 0,
                            }}
                            className="pc_medium"
                          >
                            {" | "}
                            {this.state.guestInfo?.name}
                          </Text>
                        </H6>
                      </Div>
                    )}

                    <Div
                      id="doctor_listing_sidemenu_appointment_div"
                      style={{
                        border: `1px solid ${UIColor.lineBorderFill.color}`,
                        display: this.state.appointmentUpdate
                          ? "none"
                          : "block",
                        marginBottom: 20,
                        borderRadius: 8,
                        padding: 16,
                      }}
                    >
                      <H6
                        id="doctor_listing_sidemenu_booking_by_h6"
                        inLineStyles={{
                          fontSize: 16,
                          color: UIColor.primaryText.color,
                          letterSpacing: 0,
                          lineHeight: 1,
                        }}
                        className="pc_medium"
                      >
                        BOOKING BY
                        <Text
                          id="doctor_listing_sidemenu_booking_by_text"
                          inLineStyles={{
                            color: UIColor.secondaryColor.color,
                            fontSize: 16,
                            letterSpacing: 0,
                          }}
                          className="pc_medium"
                        >
                          {" | "}
                          {this.state.guestBookerInfo?.name}
                        </Text>
                      </H6>
                    </Div>
                    <Div
                      id="doctor_listing_sidemenu_otp_div"
                      inLineStyles={{
                        backgroundColor: UIColor.differentiationBackground.color,
                        padding: 30,
                        borderRadius: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingBottom: 0,
                      }}
                    >
                      <H6
                        id="doctor_listing_sidemenu_otp_verification_h6"
                        inLineStyles={{
                          marginBottom: 24,
                          fontSize: 16,
                          letterSpacing: 0,
                          color: UIColor.primaryText.color
                        }}
                        className="pc_medium"
                      >
                        OTP Verification
                      </H6>
                      <Text
                        id="doctor_listing_sidemenu_otp_sent_text"
                        className="pc_regular"
                        inLineStyles={{
                          fontSize: 14,
                          textAlign: "center",
                          letterSpacing: 0,
                          color: UIColor.tertiaryText.color,
                        }}
                      >
                        Please enter the OTP sent to{" "}
                        <Text
                          id="doctor_listing_sidemenu_otp_number_text"
                          inLineStyles={{
                            color: UIColor.secondaryText.color,
                            letterSpacing: 0,
                            fontSize: 14,
                          }}
                          className="pc_medium"
                        >
                          {this.state.appointmentUpdate
                            ? this.state?.otpNumber
                            : this.state.guestBookerInfo?.mobileNo?.mobileNo}
                        </Text>
                        <Link
                          id="doctor_listing_sidemenu_change_link"
                          color="textPrimary"
                          underLine="none"
                          inLineStyles={{
                            color: UIColor.primaryColor.text,
                            letterSpacing: 0,
                            display: this.state.appointmentUpdate
                              ? "none"
                              : "unset",
                            textDecoration: "none",
                            fontSize: 14,
                          }}
                          className="pc_medium"
                          onClick={() => {
                            this.setState(
                              {
                                meageget: {},
                              },
                              () => {
                                this.goBack();
                              }
                            );
                          }}
                          name={"Change"}
                        />
                      </Text>
                      <Div
                        id="doctor_listing_sidemenu_change_div"
                        inLineStyles={{ marginBottom: 40, marginTop: 30 }}
                      >
                        <OtpInput
                          id="doctor_listing_sidemenu_otpinput"
                          numberOfInputs={6}
                          variant="standard"
                          type="text"
                          focusTextColor={UIColor.secondaryColor.color}
                          focusBorderColor={UIColor.secondaryColor.color}
                          inLineStyles={{ backgroundColor: "transparent" }}
                          onChange={(e) => {
                            this.setState({
                              ...this.state,
                              otpError: false
                            })
                            this.changeState("otp", e)
                          }}
                          value={this.state.otp}
                          // style={ { display : "flex",justifyContent : "center",alignItems : "center",paddingLeft : "" } }
                          className={this.state?.otpError && "otpError"}
                        />
                      </Div>
                      <Text
                        id="doctor_listing_sidemenu_didnt_receive_text"
                        inLineStyles={{
                          color: UIColor.tertiaryText.color,
                          fontSize: 12,
                          letterSpacing: 0,
                        }}
                        className="pc_regular"
                      >
                        Didn't receive OTP ?{" "}
                        <Link
                          id="doctor_listing_sidemenu_didnt_receive_link"
                          color="textPrimary"
                          underLine="none"
                          inLineStyles={{
                            color: UIColor.primaryText.color,
                            letterSpacing: 0,
                            fontSize: 12,
                          }}
                          onClick={() => this.handleresendOTP()}
                          className="pc_medium"
                          name={"Resend OTP"}
                        />
                      </Text>
                      {this.state.meageget?.payload?.data?.message ? (
                        <text
                          id="doctor_listing_sidemenu_message_text"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "12px",
                            marginBottom: "6px",
                            marginTop: "6px",
                            color: UIColor.success.color,
                            textAlign: "center",
                          }}
                        >
                          {this.state.meageget?.payload?.data?.message}
                        </text>
                      ) : null}

                      <Div
                        id="doctor_listing_sidemenu_message_div"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 24,
                        }}
                      ></Div>
                    </Div>
                    <Div
                      id="doctor_listing_sidemenu_changescreen_div"
                      style={{ textAlign: "center", marginTop: "24px" }}
                    >
                      <Button
                        id="doctor_listing_sidemenu_confirm_button"
                        // key={"7"}
                        onClick={() => this.changeScreen()}
                        type="button"
                        variant="outlined"
                        name={
                          this.props.upsertAppointment.loading ||
                            this.props.updateAppointment.loading ||
                            this.props.readPerson.loading ||
                            this.props.upsertPerson.loading ||
                            this.props.addRelatedPerson.loading ||
                            this.props.signIn.loading ||
                            this.props?.checkAppointmentAgainstPatient?.loading
                            ? "Updating..."
                            : "Confirm"
                        }
                        style={{
                          backgroundColor: UIColor.primaryColor.color,
                          borderColor: UIColor.primaryColor.color,
                          borderRadius: "8px",
                          padding: "10px 15px",
                          fontSize: "14px",
                          height: "40px",
                        }}
                        disabled={
                          this.props.upsertAppointment.loading ||
                          this.props.updateAppointment.loading ||
                          this.props.readPerson.loading ||
                          this.props.upsertPerson.loading ||
                          this.props.addRelatedPerson.loading ||
                          this.props.signIn.loading ||
                          this.props.checkAppointmentAgainstPatient?.loading
                        }
                        className="pc_medium"
                      ></Button>
                    </Div>
                  </Div>
                ) : (
                  <Div id="doctor_listing_sidemenu_booking_div">
                    <Card
                      id="doctor_listing_sidemenu_booking_card"
                      style={{ marginBottom: "8px", borderRadius: 8 }}
                    >
                      <CardActions
                        onClick={() => this.handleOpen(0)}
                        disableSpacing
                        style={{
                          padding: 0,
                          paddingLeft: 16,
                          paddingBottom: 0,
                          cursor: "pointer",
                        }}
                      >
                        <Text
                          id="doctor_listing_sidemenu_booking_text"
                          key={"0"}
                          name={
                            this.props?.guestInfo?.data?.type === 0
                              ? "BOOKING BY"
                              : "BOOKING FOR"
                          }
                          style={{
                            fontSize: "16px",
                            letterSpacing: 0,
                            lineHeight: 1,
                            color: UIColor.primaryText.color,
                          }}
                          className="pc_medium"
                        />

                        <IconButton
                          id="doctor_listing_sidemenu_collapse_iconbutton"
                          className={clsx(classes.expand, {
                            [classes.expandOpen]:
                              this.state.collpaseOpen === 0 ? true : false,
                          })}
                          aria-expanded={
                            this.state.collpaseOpen === 0 ? true : false
                          }
                          aria-label="show more"
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                      </CardActions>
                      <Collapse
                        id="doctor_listing_sidemenu_collapse"
                        className={classes.body}
                        in={this.state.collpaseOpen === 0 ? true : false}
                        // open={this.state.collpaseOpen === 0 ? true : false}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Div
                          id="doctor_listing_sidemenu_patient_name_div"
                          className={classes.label}
                        >
                          <TextInput
                            id="doctor_listing_sidemenu_patient_name_label_text"
                            // key={"0"}
                            placeholder=""
                            type="text"
                            name="text"
                            variant="outlined"
                            helperTextType="error"
                            label={
                              <H6
                                id="doctor_listing_sidemenu_patient_name_label_span"
                                className="pc_regular"
                                style={{
                                  fontSize: "12px",
                                  color: UIColor.secondaryText.color,
                                  margin: 0,
                                  marginBottom: "8px",
                                  letterSpacing: 0,
                                  lineHeight: 1,
                                }}
                              >
                                Patient name{" "}
                                <span style={{ color: UIColor.error.color }}>*</span>
                              </H6>
                            }
                            autoFocus={false}
                            autoComplete="off"
                            search={false}
                            borderColor={UIColor.lineBorderFill.color}
                            padding="10"
                            margin="0"
                            elevation="0"
                            maxLength={30}
                            value={this.state.guestInfo?.name}
                            onChange={(e) => {
                              const key =
                                this.props?.guestInfo?.data?.type === 0
                                  ? "guestBookerInfo"
                                  : "guestInfo";
                              this.changeGuestInfo(
                                "guestInfo",
                                "name",
                                e.target.value
                              );
                              this.setState({
                                ...this.state,
                                error: {
                                  [key]: {
                                    name: !checkName(e.target.value),
                                  },
                                },
                              });
                            }}
                            className={classes.textField}
                            error={(this.props?.guestInfo?.data?.type === 0
                              ? this.state.error?.guestBookerInfo?.name
                              : this.state?.error?.guestInfo?.name) && true}
                          />
                          {(this.props?.guestInfo?.data?.type === 0
                            ? this.state.error?.guestBookerInfo?.name
                            : this.state?.error?.guestInfo?.name) && (
                              <div id="doctor_listing_sidemenu_please_enter_div">
                                <Text
                                  id="doctor_listing_sidemenu_please_enter_label_text"
                                  style={{ color: UIColor.error.color, fontSize: 14 }}
                                  className="pc_regular"
                                >
                                  {"Please Enter a Valid Name"}
                                </Text>
                              </div>
                            )}
                        </Div>
                        <div style={{ marginBottom: "16px" }}>
                          <DobAge
                            parentId="guestsignin_myself_select_dob_textinput"
                            showAge
                            dateLabel="DOB"
                            ageValue={this.calculateAge(this.state?.age?.val)}
                            isRequired
                            dateValue={this.state.age?.val}
                            onChange={(value) => {
                              if (value) {
                                const milli = moment(
                                  value,
                                  "YYYY-MM-DD"
                                ).valueOf();
                                const date = {
                                  val: moment(value, "YYYY-MM-DD").format(
                                    "DD-MM-YYYY"
                                  ),
                                  milli,
                                };
                                this.changeGuestInfo(
                                  "guestInfo",
                                  "dob",
                                  date.val
                                );
                                this.changeGuestInfo("guestInfo", "age", date);
                                this.changeState("age", date);
                              } else {
                                this.changeGuestInfo(
                                  "guestInfo",
                                  "dob",
                                  "",
                                );
                                this.changeGuestInfo("guestInfo", "age", {
                                  val: "",
                                  milli: null,
                                });
                                this.changeState("age", {
                                  val: "",
                                  milli: null,
                                });
                              }
                            }}
                            maxDate={new Date()}
                            // minDate={moment(new Date())
                            //   .subtract(200, "years")
                            //   .format("YYYY-MM-DD")}
                            placeholder="mm/dd/yyyy"
                            minDate={(moment(new Date()).subtract(200, 'years'))._d}
                            error={((this.props?.guestInfo?.data?.type === 0
                              ? this.state.error?.guestBookerInfo?.dob
                              : this.state?.error?.guestInfo?.dob) || this.checkAge(this.state?.age?.val)) && true}
                            top={39}
                            right={10}
                          />
                        </div>
                        {(this.props?.guestInfo?.data?.type === 0
                          ? this.state.error?.guestBookerInfo?.dob
                          : this.state?.error?.guestInfo?.dob) && (
                            <div id="doctor_listing_sidemenu_please_select_dob_div">
                              <Text
                                id="doctor_listing_sidemenu_please_select_dob_text"
                                style={{ color: UIColor.error.color, fontSize: 14 }}
                              >
                                {"Please Select DOB"}
                              </Text>
                            </div>
                          )}
                        <Div
                          id="doctor_listing_sidemenu_gender_div"
                          className={classes.label}
                        >
                          <TapSelect
                            id="doctor_listing_sidemenu_gender_tapselect"
                            // key={"2"}
                            type="single"
                            fullWidth={false}
                            selectedBorderColor={UIColor.secondaryButtonColor.color} 
                            selectedBackgroundColor={UIColor.secondaryButtonColor.color}
                            selectedTextColor="#fff"
                            borderColor={UIColor.differentiationBackground.color}
                            backgroundColor={UIColor.differentiationBackground.color}
                            textColor={UIColor.secondaryText.color}
                            textSize="14"
                            iconHeight="20"
                            iconWidth="20"
                            label={
                              <H6
                                id="doctor_listing_sidemenu_gender_label_span"
                                style={{
                                  fontSize: "12px",
                                  color: UIColor.secondaryText.color,
                                  margin: 0,
                                  letterSpacing: 0,
                                  lineHeight: 1,
                                }}
                                className="pc_regular"
                              >
                                Gender <span style={{ color: UIColor.error.color }}>*</span>
                              </H6>
                            }
                            options={this.genderOptions}
                            defaultSelected={this.state.guestInfo?.gender}
                            onChange={(e) =>
                              this.changeGuestInfo(
                                "guestInfo",
                                "gender",
                                this.state?.guestInfo?.gender?.value ===
                                  e?.value
                                  ? {}
                                  : e
                              )
                            }
                            // inLineStyles={{
                            //   margin: 0,
                            //   marginBottom: 6,
                            //   padding: "4px 12px !important",
                            //   marginRight: 6,
                            // }}
                            inLineStyles={{
                              // borderColor: UIColor.secondaryButtonColor.color,
                            }}
                            className={classes.tapSelect}
                          />
                          {(this.props?.guestInfo?.data?.type === 0
                            ? this.state.error?.guestBookerInfo?.gender
                            : this.state?.error?.guestInfo?.gender) && (
                              <div id="doctor_listing_sidemenu_select_gender_div">
                                <Text
                                  id="doctor_listing_sidemenu_select_gender_text"
                                  style={{ color: UIColor.error.color, fontSize: 14 }}
                                >
                                  {"Please Select Gender"}
                                </Text>
                              </div>
                            )}
                        </Div>
                        <H6
                          id="doctor_listing_sidemenu_number_span"
                          style={{
                            fontSize: "12px",
                            color: UIColor.secondaryText.color,
                            margin: 0,
                            letterSpacing: 0,
                            lineHeight: 1,
                            marginBottom: 8,
                          }}
                          className="pc_regular"
                        >
                          Mobile number
                          <span
                            id="doctor_listing_sidemenu_number_span"
                            style={{ color: UIColor.error.color }}
                          >
                            *
                          </span>
                        </H6>
                        <div className={classes.mobile}>
                          <MobileNumberWithCode
                            id="doctor_listing_sidemenu_number_mobilenumberwithcode"
                            // key={"3"}
                            type="number"
                            label="Phone number"
                            borderColor={UIColor.lineBorderFill.color}
                            borderRadius="6"
                            disabled={false}
                            maxLength={30}
                            value={this.state.guestInfo?.mobileNo?.mobileNo}
                            dropdown={this.state.guestInfo?.mobileNo?.countryData}
                            dropdownData={this.props.countryList?.data}
                            onChange={(e) => {

                              this.setState({
                                ...this.state,
                                error: {
                                  ...this.state.error,
                                  guestInfo: {
                                    ...this.state.error?.guestInfo,
                                    callingCodes: false,
                                    mobileNo: false
                                  }
                                }

                              }, () => {
                                this.changeGuestInfo("guestInfo", "mobileNo", e)
                              });



                            }
                            }
                            className={(this.state.error?.guestInfo?.callingCodes ||
                              this.state.error?.guestInfo?.mobileNo) && "errorBorder"}
                          // style={ { marginTop : "24px",paddingTop : "" } }
                          // inLineStyles={{
                          //   marginBottom:
                          //     this.state.error?.guestBookerInfo?.callingCodes ||
                          //       this.state.error?.guestBookerInfo?.mobileNo
                          //       ? 0
                          //       : 16,
                          // }}
                          />
                          {this.props?.guestInfo?.data?.type === 0 &&
                            (this.state.error?.guestBookerInfo?.mobileNo ||
                              this.state.error?.guestBookerInfo
                                ?.callingCodes) && (
                              <Div
                                id="doctor_listing_sidemenu_number_please_select_country_divs"
                                style={{ marginBottom: 10 }}
                              >
                                {this.state.error?.guestBookerInfo
                                  ?.callingCodes && (
                                    <Text
                                      id="doctor_listing_sidemenu_number_please_select_country_text"
                                      style={{ color: UIColor.error.color, fontSize: 14 }}
                                      className="pc_regular"
                                    >
                                      {"Please select the country code"}
                                    </Text>
                                  )}
                                {!this.state.error?.guestBookerInfo
                                  ?.callingCodes &&
                                  this.state.error?.guestBookerInfo
                                    ?.mobileNo && (
                                    <Text
                                      id="doctor_listing_sidemenu_please_enter_mobile_text"
                                      style={{ color: UIColor.error.color, fontSize: 14 }}
                                      className="pc_regular"
                                    >
                                      {"Please enter valid mobile number"}
                                    </Text>
                                  )}
                              </Div>
                            )}

                          {this.props?.guestInfo?.data?.type === 1 &&
                            (this.state.error?.guestInfo?.mobileNo ||
                              this.state.error?.guestInfo?.callingCodes) && (
                              <Div
                                id="doctor_listing_sidemenu_please_country_code_div"
                                style={{ marginBottom: 10 }}
                              >
                                {this.state.error?.guestInfo?.callingCodes && (
                                  <Text
                                    id="doctor_listing_sidemenu_please_country_code_text"
                                    style={{ color: UIColor.error.color, fontSize: 14 }}
                                  >
                                    {"Please select the country code"}
                                  </Text>
                                )}
                                {!this.state.error?.guestInfo?.callingCodes &&
                                  this.state.error?.guestInfo?.mobileNo && (
                                    <Text
                                      id="doctor_listing_sidemenu_please_mobile_number"
                                      style={{ color: UIColor.error.color, fontSize: 14 }}
                                    >
                                      {"Please enter the mobile number"}
                                    </Text>
                                  )}
                              </Div>
                            )}
                        </div>

                        <TermsAndConditions
                          id="doctor_listing_sidemenu_send_termsand_conditions"
                          value={this.state.guestInfo?.isMobile}
                          checkboxSize={14}
                          // key={"4"}
                          checkboxTickColor={"#fff"}
                          activeCheckboxBorderColor={UIColor.secondaryButtonColor.color}
                          checkboxBorderColor={UIColor.lineBorderFill.color}
                          checkboxBackgroundColor={UIColor.differentiationBackground.color}
                          activeCheckboxBackgroundColor={UIColor.secondaryButtonColor.color}
                          onChange={(value) =>
                            this.changeGuestInfo("guestInfo", "isMobile", value)
                          }
                          lable={
                            <H6
                              id={`sdoctor_listing_sidemenu_send_termsand_conditions`}
                              style={{
                                fontSize: "12px",
                                color: UIColor.secondaryText.color,
                                margin: 0,
                                letterSpacing: 0,
                              }}
                              className={"pc_regular"}
                            >
                              Send appointment update and notification to this
                              mobile number
                            </H6>
                          }
                          inLineStyles={{ lineHeight: 1, marginBottom: 16 }}
                          className={classes.termsAndConditions}
                        />
                        <Div
                          id="doctor_listing_sidemenu_mail_id_div"
                          className={classes.label}
                        >
                          <TextInput
                            id="doctor_listing_sidemenu_mail_id_text"
                            // key={"5"}
                            placeholder=""
                            type="text"
                            name="text"
                            variant="outlined"
                            helperTextType="error"
                            label={
                              <H6
                                id="doctor_listing_sidemenu_mail_id_span"
                                style={{
                                  fontSize: "12px",
                                  color: UIColor.secondaryText.color,
                                  margin: 0,
                                  letterSpacing: 0,
                                  lineHeight: 1,
                                  marginBottom: 8,
                                }}
                              >
                                Mail Id
                                <span style={{ color: UIColor.error.color }}></span>
                              </H6>
                            }
                            autoFocus={false}
                            autoComplete="off"
                            search={false}
                            borderColor={UIColor.lineBorderFill.color}
                            padding="10"
                            margin="0"
                            elevation="0"
                            value={this.state.guestInfo?.mailId}
                            onChange={(e) =>
                              this.changeGuestInfo(
                                "guestInfo",
                                "mailId",
                                e.target.value
                              )
                            }
                            className={classes.textField}
                          // style={{ marginBottom: '14px', marginTop: '2%' }}
                          />
                          {(this.props?.guestInfo?.data?.type === 0
                            ? this.state.error?.guestBookerInfo?.mailId
                            : this.state?.error?.guestInfo?.mailId) && (
                              <div id="doctor_listing_sidemenu_please_nter_email_div">
                                <Text
                                  id="doctor_listing_sidemenu_please_enter_email_text"
                                  style={{ color: UIColor.error.color, fontSize: 14 }}
                                >
                                  {"Please Enter Valid Email ID"}
                                </Text>
                              </div>
                            )}
                        </Div>
                        <TermsAndConditions
                          id="doctor_listing_sidemenu_send_termsand_conditions"
                          onChange={(value) =>
                            this.changeGuestInfo("guestInfo", "isEmail", value)
                          }
                          checkboxSize={14}
                          checkboxTickColor={"#fff"}
                          activeCheckboxBorderColor={UIColor.secondaryButtonColor.color}
                          checkboxBorderColor={UIColor.lineBorderFill.color}
                          checkboxBackgroundColor={UIColor.differentiationBackground.color}
                          activeCheckboxBackgroundColor={UIColor.secondaryButtonColor.color}
                          value={this.state.guestInfo?.isEmail}
                          lable={
                            <H6
                              id={`sdoctor_listing_sidemenu_send_termsand_conditions`}
                              style={{
                                fontSize: "12px",
                                color: UIColor.secondaryText.color,
                                margin: 0,
                                letterSpacing: 0,
                              }}
                              className={"pc_regular"}
                            >
                              Send appointment update and notification to this
                              mail id
                            </H6>
                          }
                          inLineStyles={{ lineHeight: 1 }}
                          className={classes.termsAndConditions}
                        />

                        <Button
                          id="doctor_listing_sidemenu_send_otp_confirm_button"
                          // key={"7"}
                          onClick={() => {
                            this.props?.guestInfo?.data?.type === 0
                              ? this.handleMySelfSignUp()
                              : this.checkValidationsForOthers();
                          }}
                          type="button"
                          variant="outlined"
                          name={
                            this.props?.guestInfo?.data?.type === 0
                              ? this.props.generateOTP?.loading ||
                                this.props.registerUsers?.loading
                                ? "Sending Otp..."
                                : "Send OTP & Confirm"
                              : "Next"
                          }
                          style={{
                            margin: "auto",
                            backgroundColor: UIColor.primaryColor.color,
                            borderColor: UIColor.primaryColor.color,
                            borderRadius: "8px",
                            display: "flex",
                            justifyContent: "center",
                            padding: "10px 15px",
                            fontSize: "14px",
                            marginTop: 32,
                            height: 40,
                          }}
                          className="pc_medium"
                        ></Button>
                      </Collapse>
                    </Card>
                    {this.props?.guestInfo?.data?.type === 0 ? null : (
                      <Card id="doctor_listing_sidemenu_booking_by_card">
                        <CardActions
                          id="doctor_listing_sidemenu_booking_by_cardactions"
                          // onClick={() => this.checkValidationsForOthers()}
                          disableSpacing
                          style={{
                            padding: 0,
                            paddingLeft: 16,
                            paddingBottom: 0,
                            cursor: "pointer",
                          }}
                        >
                          <Text
                            id="doctor_listing_sidemenu_booking_by_text"
                            key={"0"}
                            name="BOOKING BY"
                            style={{
                              fontSize: "16px",
                              letterSpacing: 0,
                              lineHeight: 1,
                              color: UIColor.primaryText.color,
                            }}
                            className="pc_medium"
                          />

                          <IconButton
                            id="doctor_listing_sidemenu_collapse_iconbutton"
                            className={clsx(classes.expand, {
                              [classes.expandOpen]:
                                this.state.collpaseOpen === 1 ? true : false,
                            })}
                            aria-expanded={
                              this.state.collpaseOpen === 1 ? true : false
                            }
                            aria-label="show more"
                          >
                            <ExpandMoreIcon id="doctor_listing_sidemenu_expandmoreicon" />
                          </IconButton>
                        </CardActions>
                        <Collapse
                          id="doctor_listing_sidemenu_collapse"
                          className={classes.body}
                          in={this.state.collpaseOpen === 1 ? true : false}
                          // open={this.state.collpaseOpen === 0 ? true : false}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Div
                            id="doctor_listing_sidemenu_collapse_div"
                            className={classes.label}
                          >
                            <TextInput
                              id="doctor_listing_sidemenu_collapse_name_textinput"
                              // key={"0"}
                              placeholder=""
                              type="text"
                              name="text"
                              variant="outlined"
                              helperTextType="error"
                              label={
                                <H6
                                  id="doctor_listing_sidemenu_collapse_name_label_textinput"
                                  className="pc_regular"
                                  style={{
                                    fontSize: "12px",
                                    color: UIColor.secondaryText.color,
                                    margin: 0,
                                    marginBottom: "8px",
                                    letterSpacing: 0,
                                    lineHeight: 1,
                                  }}
                                >
                                  What's your name ?
                                  <span style={{ color: UIColor.error.color }}>*</span>
                                </H6>
                              }
                              autoFocus={false}
                              autoComplete="off"
                              search={false}
                              borderColor={UIColor.lineBorderFill.color}
                              padding="10"
                              margin="0"
                              elevation="0"
                              value={this.state.guestBookerInfo?.name}
                              onChange={(e) => {
                                this.changeGuestInfo(
                                  "guestBookerInfo",
                                  "name",
                                  e.target.value
                                );
                                this.setState({
                                  ...this.state,
                                  error: {
                                    guestBookerInfo: {
                                      name: !checkName(e.target.value),
                                    },
                                  },
                                });
                              }}
                              className={classes.textField}
                              error={this.state.error?.guestBookerInfo?.name && true}
                            />
                            {this.state.error?.guestBookerInfo?.name && (
                              <div
                                id="doctor_listing_sidemenu_collapse_please_name_div"
                                className={"pc_regular"}
                              >
                                <Text
                                  id="doctor_listing_sidemenu_collapse_please_name_text"
                                  className="pc_regular"
                                  style={{ color: UIColor.error.color, fontSize: 14 }}
                                >
                                  {"Please Enter a Valid Name"}
                                </Text>
                              </div>
                            )}
                          </Div>
                          <Div
                            id="doctor_listing_sidemenu_collapse_relationship_div"
                            className={classes.label}
                          >
                            <TapSelect
                              id="doctor_listing_sidemenu_collapse_relationship_tapselect"
                              // key={"2"}
                              type="single"
                              fullWidth={false}
                              textSize="16"
                              iconHeight="20"
                              iconWidth="20"
                              selectedBorderColor={UIColor.primaryColor.color} 
                              selectedBackgroundColor={UIColor.primaryColor.color}
                              selectedTextColor="#fff"
                              borderColor={UIColor.differentiationBackground.color}
                              backgroundColor={UIColor.differentiationBackground.color}
                              textColor={"#000"}
                              label={
                                <H6
                                  id="doctor_listing_sidemenu_collapse_relationship_label_span"
                                  style={{
                                    fontSize: "12px",
                                    color: UIColor.secondaryText.color,
                                    margin: 0,
                                    letterSpacing: 0,
                                    lineHeight: 1,
                                  }}
                                  className="pc_regular"
                                >
                                  What's your relationship with{" "}
                                  {this.state.guestInfo.name} ?
                                  <span style={{ color: UIColor.error.color }}> *</span>
                                </H6>
                              }
                              options={this.relationshipOptions}
                              defaultSelected={{
                                value: this.state.guestBookerInfo?.relationship,
                                label: this.state.guestBookerInfo?.relationship,
                              }}
                              onChange={(e) => {
                                this.changeGuestInfo(
                                  "guestBookerInfo",
                                  "relationship",
                                  this.state?.guestBookerInfo?.relationship
                                    ?.value === e?.value
                                    ? {}
                                    : e.value
                                );
                                this.setState({
                                  ...this.state,
                                  error: {
                                    ...this.state.error,
                                    guestBookerInfo: {
                                      ...this.state.error?.guestBookerInfo,
                                      relationship: false,
                                    },
                                  },
                                });
                              }
                              }
                              // inLineStyles={{
                              //   padding: "4px 12px !important",
                              //   margin: 6,
                              // }}
                              className={classes.tapSelect}
                            />
                            {this.state.error?.guestBookerInfo
                              ?.relationship && (
                                <div
                                  id="doctor_listing_sidemenu_collapse_relationship_label_div"
                                  className={"pc_regular"}
                                >
                                  <Text
                                    id="doctor_listing_sidemenu_collapse_relationship_label_text"
                                    style={{ color: UIColor.error.color, fontSize: 14 }}
                                  >
                                    {"Please Select Relationship"}
                                  </Text>
                                </div>
                              )}
                          </Div>
                          <H6
                            id="doctor_listing_sidemenu_collapse_phone_number_label_span"
                            style={{
                              fontSize: "12px",
                              color: UIColor.secondaryText.color,
                              margin: 0,
                              letterSpacing: 0,
                              lineHeight: 1,
                              marginBottom: 8,
                            }}
                            className="pc_regular"
                          >
                            Enter your phone number
                            <span style={{ color: UIColor.error.color }}>{" *"}</span>
                          </H6>
                          <div className={classes.mobile}>
                            <MobileNumberWithCode
                              id="doctor_listing_sidemenu_collapse_mobilenumberwithocde"
                              // key={"3"}
                              type="number"
                              borderColor={UIColor.lineBorderFill.color}
                              borderRadius="6"
                              disabled={false}
                              maxLength={30}
                              value={
                                this.state.guestBookerInfo?.mobileNo?.mobileNo
                              }
                              dropdown={
                                this.state.guestBookerInfo?.mobileNo?.countryData
                              }
                              dropdownData={this.props.countryList?.data}
                              onChange={(e) => {
                                this.setState({
                                  ...this.state,
                                  error: {
                                    ...this.state.error,
                                    guestBookerInfo: {
                                      ...this.state.error?.guestBookerInfo,
                                      callingCodes: false,
                                      mobileNo: false
                                    }
                                  }
                                }, () => {
                                  this.changeGuestInfo(
                                    "guestBookerInfo",
                                    "mobileNo",
                                    e
                                  )
                                });

                              }
                              }
                              className={(this.state.error?.guestBookerInfo?.callingCodes ||
                                this.state.error?.guestBookerInfo?.mobileNo) && "errorBorder"}
                            // style={ { marginTop : "24px",paddingTop : "" } }
                            // inLineStyles={{
                            //   marginBottom:
                            //     this.state.error?.guestBookerInfo
                            //       ?.callingCodes ||
                            //       this.state.error?.guestBookerInfo?.mobileNo
                            //       ? 0
                            //       : 16,
                            // }}
                            />
                            {(this.state.error?.guestBookerInfo?.mobileNo ||
                              this.state.error?.guestBookerInfo
                                ?.callingCodes) && (
                                <Div
                                  id="doctor_listing_sidemenu_collapse_country"
                                  className={"pc_regular"}
                                  style={{ marginBottom: 10 }}
                                >
                                  {this.state.error?.guestBookerInfo
                                    ?.callingCodes && (
                                      <Text
                                        id="doctor_listing_sidemenu_collapse_country_text"
                                        style={{ color: UIColor.error.color, fontSize: 14 }}
                                      >
                                        {"Please select the country code"}
                                      </Text>
                                    )}
                                  {!this.state.error?.guestBookerInfo
                                    ?.callingCodes &&
                                    this.state.error?.guestBookerInfo
                                      ?.mobileNo && (
                                      <Text
                                        id="doctor_listing_sidemenu_collapse_number_text"
                                        style={{ color: UIColor.error.color, fontSize: 14 }}
                                      >
                                        {"Please enter the mobile number"}
                                      </Text>
                                    )}
                                </Div>
                              )}
                          </div>
                          <TermsAndConditions
                            id="doctor_listing_sidemenu_collapse_number_termsandconditons"
                            checkboxSize={14}
                            onChange={(value) =>
                              this.changeGuestInfo(
                                "guestBookerInfo",
                                "isMobile",
                                value
                              )
                            }
                            value={this.state.guestBookerInfo?.isMobile}
                            checkboxTickColor={"#fff"}
                            activeCheckboxBorderColor={UIColor.secondaryButtonColor.color}
                            checkboxBorderColor={UIColor.lineBorderFill.color}
                            checkboxBackgroundColor={UIColor.differentiationBackground.color}
                            activeCheckboxBackgroundColor={UIColor.secondaryButtonColor.color}
                            lable={
                              <H6
                                id={`sdoctor_listing_sidemenu_send_termsand_conditions`}
                                style={{
                                  fontSize: "12px",
                                  color: UIColor.secondaryText.color,
                                  margin: 0,
                                  letterSpacing: 0,
                                }}
                                className={"pc_regular"}
                              >
                                Send appointment update and notification to this
                                phone number
                              </H6>
                            }
                            inLineStyles={{ lineHeight: 1, marginBottom: 16 }}
                            className={classes.termsAndConditions}
                          />
                          <Div
                            id="doctor_listing_sidemenu_collapse_maildid_div"
                            className={classes.label}
                          >
                            <TextInput
                              id="doctor_listing_sidemenu_collapse_maildid_input"
                              // key={"5"}
                              placeholder=""
                              type="text"
                              name="text"
                              variant="outlined"
                              helperTextType="error"
                              label={
                                <H6
                                  id="doctor_listing_sidemenu_bookingby_mail_id_span"
                                  style={{
                                    fontSize: "12px",
                                    color: UIColor.secondaryText.color,
                                    margin: 0,
                                    letterSpacing: 0,
                                    lineHeight: 1,
                                    marginBottom: 8,
                                  }}
                                >
                                  Mail Id
                                </H6>
                              }
                              autoFocus={false}
                              autoComplete="off"
                              search={false}
                              borderColor={UIColor.lineBorderFill.color}
                              padding="10"
                              margin="0"
                              elevation="0"
                              value={this.state.guestBookerInfo?.mailId}
                              onChange={(e) =>
                                this.changeGuestInfo(
                                  "guestBookerInfo",
                                  "mailId",
                                  e.target.value
                                )
                              }
                              className={classes.textField}
                            />
                            {this.state.error?.guestBookerInfo?.mailId && (
                              <div id="doctor_listing_sidemenu_collapse_please_eneter_email_div">
                                <Text
                                  id="doctor_listing_sidemenu_collapse_please_eneter_email_text"
                                  style={{ color: UIColor.error.color, fontSize: 14 }}
                                >
                                  {"Please Enter Valid Email ID"}
                                </Text>
                              </div>
                            )}
                          </Div>

                          <TermsAndConditions
                            id="doctor_listing_sidemenu_collapse_send_appointment_termsandcondition"
                            // value={true}
                            // key={"6"}
                            onChange={(value) =>
                              this.changeGuestInfo(
                                "guestBookerInfo",
                                "isEmail",
                                value
                              )
                            }
                            value={this.state.guestBookerInfo?.isEmail}
                            checkboxSize={14}
                            checkboxTickColor={"#fff"}
                            activeCheckboxBorderColor={UIColor.secondaryButtonColor.color}
                            checkboxBorderColor={UIColor.lineBorderFill.color}
                            checkboxBackgroundColor={UIColor.differentiationBackground.color}
                            activeCheckboxBackgroundColor={UIColor.secondaryButtonColor.color}
                            lable={
                              <H6
                                id={`sdoctor_listing_sidemenu_send_termsand_conditions`}
                                style={{
                                  fontSize: "12px",
                                  color: UIColor.secondaryText.color,
                                  margin: 0,
                                  letterSpacing: 0,
                                }}
                                className={"pc_regular"}
                              >
                                Send appointment update and notification to this
                                mail Id
                              </H6>
                            }
                            inLineStyles={{ lineHeight: 1 }}
                            className={classes.termsAndConditions}
                          />

                          <Button
                            id="doctor_listing_sidemenu_collapse_send_otp_button"
                            // key={"7"}
                            onClick={() => this.sendOTPandConfirm()}
                            type="button"
                            variant="outlined"
                            name={
                              this.props.generateOTP?.loading ||
                                this.props.registerUsers?.loading
                                ? "Sending Otp..."
                                : "Send OTP & Confirm"
                            }
                            disabled={
                              this.props.generateOTP?.loading ||
                              this.props.registerUsers?.loading
                            }
                            style={{
                              margin: "auto",
                              marginTop: "34px",
                              backgroundColor: UIColor.primaryColor.color,
                              borderColor: UIColor.primaryColor.color,
                              borderRadius: "8px",
                              display: "flex",
                              justifyContent: "center",
                              padding: "10px 16px",
                              fontSize: "14px",
                              height: 40,
                            }}
                            className="pc_medium"
                          ></Button>
                        </Collapse>
                      </Card>
                    )}
                  </Div>
                )}
              </SideMenu>
            </Col>
          </Row>
          <SideMenu
            id="doctor_listing_sidemenu_collapse_sidemenu"
            open={this.state.bulkBooking}
            direction="right"
            width={585}
            color="default"
          // onClose={() => this.toggleSideBar(false)}
          >
            <Sidemenu1 parent_id={"doctor_listing"} />
          </SideMenu>
        </Container>
        {(this.state?.modalOpen && this.state?.doctorName) &&
          <Dialog
            open={this.state?.modalOpen}
            onClose={this.handleModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Booking Confirmation?"}</DialogTitle>
            <DialogContent>
            {this.state?.doctorCount > 1 ?
							<DialogContentText id="alert-dialog-description">
							There are <span style={{fontWeight: "bolder", color: "black"}}> {this.state?.doctorCount}</span> appointments for same date time, do you want to continue with this boooking?
							</DialogContentText>
							:
							<DialogContentText id="alert-dialog-description">
							There is an appointment with <span style={{fontWeight: "bolder", color: "black"}}> Dr.{this.state?.doctorName}</span> for same date time, do you want to continue with this boooking?
							</DialogContentText>
						}
            </DialogContent>
            <DialogActions>
            <Button
                  id="patient_detail_put_hold_button"
                  style={{
                    width: "87px",
                    height: "36px",
                    border: `1px solid ${UIColor.greyBackground.color}`,
                    borderRadius: "8px",
                    opacity: 1,
                    color: "#323232",
                    background: UIColor.greyBackground.color,
                    marginRight: "10px",
                    marginBottom: "10px",
                    cursor: "pointer",
                  }}
                  onClick={this.handleModalClose}
                >
                  {"No"}
                </Button>
                <Button
                  id="patient_detail_signoff_button"
                  onClick={this.handleBooking}
                  style={{
                    width: "87px",
                    height: "36px",
                    border: `1px solid ${UIColor.greyBackground.color}`,
                    borderRadius: "8px",
                    opacity: 1,
                    color: "white",
                    background: UIColor.greyBackground.color,
                    marginRight: "10px",
                    backgroundColor: UIColor.secondaryButtonColor.color,
                    borderColor: UIColor.secondaryButtonColor.color,
                    marginBottom: "10px",
                    cursor: "pointer",
                  }}
                >
                  {"Yes"}
                </Button>
            </DialogActions>
          </Dialog>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  updateAppointment: state?.appointmentReducer?.updateAppointment,
  docterListing: state?.docterListingReducer?.docterListing,
  allLanguages: state?.docterListingReducer?.allLanguages,
  docterListingProfile: state?.docterListingReducer?.docterListingProfile,
  docterListingBookingBy: state?.docterListingReducer?.docterListingBookingBy,
  registerUsers: state?.authSlice?.registerUsers,
  generateOTP: state?.authSlice?.generateOTP,
  resendOTP: state?.authSlice?.resendOTP,
  signIn: state?.authSlice?.signIn,
  guestInfo: state?.authSlice?.guestInfo,
  upsertAppointment: state?.appointmentReducer?.upsertAppointment,
  checkAppointmentAgainstPatient: state?.appointmentReducer?.checkAppointmentAgainstPatient,
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
  guestUserInfo: state?.authSlice?.guestInfo,
  readPerson: state?.personApiSlice?.readPerson,
  upsertPerson: state?.personApiSlice?.upsertPerson,
  addRelatedPerson: state?.personApiSlice?.addRelatedPerson,
  reasonForAppointment: state?.appointmentReducer?.reasonForAppointMent,
  reasonForApptCancelModify: state?.appointmentReducer?.reasonForApptCancelModify,
  countryList: state?.authSlice?.country_code_list
});

DoctorListing.contextType = AlertContext;

export default withStyles(styles)(
  connect(mapStateToProps, actions)(withRouter(DoctorListing))
);

// export default connect(mapStateToProps, actions)(withRouter(DoctorListing));
