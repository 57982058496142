import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import qdmstyles from "./styles2.json";
import { withRouter } from "react-router-dom";
import moment from "moment";

import {
  Container,
  H6,
  Divider,
  Div,
  Avatar,
  Text,
  Icons,
  Card,
  OtpInput,
  Button,
  TapSelect,
  TermsAndConditions,
} from "qdm-component-library";
import {
  checkError,
  AlertProps,
  getMobileNumber,
  upsertPerson,
  getUserInfo,
  uploadImage,
  UIColor,
} from "../../utils";
import { AlertContext } from "../../contexts";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  tapSelect: {
    "& .selectionDiv": {
      height: 40,
      width: 70,
      borderRadius: 8,
      marginRight: 4,
      marginTop: 8,
      "&:last-of-type": {
        marginRight: 0,
      },
      "& span": {
        fontFamily: "pc_regular",
        fontSize: 14,
      },
    },
    "& .active": {
      "& span": {
        fontFamily: "pc_medium !important",
      },
    },
  },
});

class AppointmentPageDrawerOtpSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      genderValue: "",
      firstNameValue: "",
      resend: "",
      added: !!props.addmmber || false,
    };
    this.getUserInfo = getUserInfo.bind(this);
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleSubmit = async () => {
    debugger
    const { otp } = this.state;
    const { addmmber } = this.props;
    // Logged In
    // => dashboard (newMemeber)
    //    => existingPerson => false
    //    => addMember => true
    //    => addmmber => false
    // => dashboard (existingMember)
    //    => existingPerson => false
    //    => addMember => false
    //    => addmmber => true
    // Sign Up
    // => homepage (newMemeber)
    //    => existingPerson => false
    //    => addMember => false
    //    => addmmber => false
    console.clear();
    console.log(this.props?.signUpData?.existingPerson);
    console.log(this.props?.signUpData?.addMember);
    console.log(addmmber);
    if (this.props?.signUpData?.existingPerson) {
      const resSignIn = await this.props.SIGN_IN({
        mobileNo: this.props.signUpData?.otpNumber,
        otp,
      });
      const status = checkError(this.props.signIn);
      if (status.isError) {
        this.setState({
          error: status.errMsg,
        });
      } else {
        if (resSignIn?.payload?.data?.access_token) {
          this.setState(
            {
              ...this.state,
              added: true,
            },
            () => {
              localStorage.removeItem("guestInfo");
              // localStorage.setItem("idval", this.props?.signUpData?.id || 0);
              localStorage.setItem(
                "token",
                btoa(JSON.stringify(resSignIn?.payload?.data))
              );
              window.location.href = "/profile";
            }
          );
        }
      }
      return;
    }
    if (this.props.signUpData?.addMember) {
      await this.props.SIGN_IN({
        mobileNo: this.props.signUpData?.otpNumber,
        otp,
      });
      const status = checkError(this.props.signIn);
      if (status.isError) {
        this.setState({
          error: status.errMsg,
        });
      } else {
        const { memberInfo: guestBookerAdd, relation } = this.props?.signUpData;
        let imageData;
        if (guestBookerAdd.image.file) {
          imageData = await uploadImage([guestBookerAdd.image.file]);
        }
        if (imageData && Array.isArray(imageData)) {
          guestBookerAdd["photo"] = imageData;
        }
        const { data: res, error } = await upsertPerson(
          this.props.READ_PERSON,
          this.props.UPSERT_PERSON,
          guestBookerAdd
        );
        if (error) {
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: res,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
        } else {
          const userInfo = await this.getUserInfo();
          let personId = userInfo.id;
          const relatedPersonInput = {
            // name: res.payload?.data?.name,
            // telecom: res.payload?.data?.telecom,
            // photo: res.payload?.data?.photo,
            ...guestBookerAdd,
            relationType: relation?.value || "",
            relationPersonId: [personId || 0],
            personId: res.payload?.data?.id || 0,
          };
          const relatedData = await this.props.ADD_RELATED_PERSON(
            relatedPersonInput
          );
          const { isError, errMsg: errMseeasge } = checkError(
            relatedData?.payload
          );
          if (isError) {
            this.context.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: errMseeasge,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
              tone: true,
            });
            return;
          }
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "Member Added Successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
          this.setState(
            {
              ...this.state,
              added: true,
            },
            () => {
              if (this.props.changeSideBar) {
                this.props.changeSideBar();
              } else {
                window.location.reload();
              }
            }
          );
        }
      }
      return;
    }
    if (addmmber) {
      if (this.state.genderValue) {
        const checkmmr = await this.props.SIGN_IN({
          mobileNo: getMobileNumber(this.props.signUpData?.mobileNo),
          otp,
        });
        if (checkmmr.payload.data.error === "invalid_grant") {
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Invalid OTP",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
          return false;
        } else {
          const userInfo = await this.getUserInfo();
          let userId = userInfo.id;
          const relatedInput = {
            phone: getMobileNumber(this.props.signUpData?.mobileNo),
            dob: this.props.signUpData?.dob,
            firstName: this.props.signUpData?.firstName,
            lastName: this.props.signUpData?.lastName,
            gender: this.props.signUpData?.gender,
            email: this.props.signUpData?.emailId || "",
            relationType: this.state.genderValue,
            relationPersonId: [userId],
            personId: this.props.signUpData?.id,
          };

          const relatedPerData = await this.props.ADD_RELATED_PERSON(
            relatedInput
          );
          // if(addmmrss){
          //   console.log('p')
          // }
          const { isError, errMsg: errMseeasge } = checkError(
            relatedPerData?.payload
          );
          if (isError) {
            this.context.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: errMseeasge,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
              tone: true,
            });
            return false;
          }
          if (this.props.updateMember) {
            this.props.updateMember([
              {
                src: this.props.signUpData?.image?.url || "",
                label: this.props.signUpData?.firstName || "",
                id: this.props.signUpData?.id,
              },
            ]);
          }
          this.setState(
            {
              ...this.state,
              added: true,
            },
            () => {
              this.props.nextSection("member_succss", this.props.signUpData);
            }
          );
        }
      } else {
        this.setState({
          error: "Please Choose Relationship",
        });
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please Choose Relationship",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      }
      return false;
    }

    await this.props.SIGN_IN({
      mobileNo: `+${this.props.signUpData?.mobileNo?.countryData?.callingCodes ?? "00"
        }${this.props.signUpData?.mobileNo.mobileNo}`,
      otp,
    });
    const status = checkError(this.props.signIn);
    if (!status.isError) {
      const {
        mobileNo,
        firstName,
        lastName,
        middleName,
        dob,
        gender,
        emailId,
        image,
        dontAddEmail,
      } = this.props?.signUpData;
      let imageData;
      if (image.file) {
        imageData = await uploadImage([image.file]);
      }
      const guestBookerAdd = {
        phone: getMobileNumber(mobileNo),
        dob,
        firstName,
        lastName,
        middleName,
        gender,
        email: !!dontAddEmail ? "" : emailId,
      };
      if (imageData && Array.isArray(imageData)) {
        guestBookerAdd["photo"] = imageData;
      }
      if (this.props?.signIn?.data?.access_token) {
        localStorage.removeItem("guestInfo");
        localStorage.setItem(
          "token",
          btoa(JSON.stringify(this.props?.signIn?.data))
        );
      }
      const { error, data } = await upsertPerson(
        this.props.READ_PERSON,
        this.props.UPSERT_PERSON,
        guestBookerAdd
      );
      if (error && data.includes("User Already Exists ")) {
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Member Already Exists. Please try Sign Up using existing member",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      } else if (error) {
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: data,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        this.setState({
          error: status.errMsg,
        });
      } else {
        this.setState(
          {
            ...this.state,
            added: true,
          },
          () => {
            this.props.nextSection("movenextscreen");
          }
        );
        // const personId = data.payload?.data?.id || 0;
        // localStorage.setItem("idval", personId);
      }
    } else {
      this.setState({
        error: status.errMsg,
      });
    }
  };

  getAge = (date) => {
    return (
      Math.floor(
        moment(new Date()).diff(moment(date, "DD/MM/YYYY"), "years", true)
      ) + "Yrs"
    );
  };
  resendOTP = async () => {
    this.changeState("otp", "");
    const res = await this.props.RESEND_OTP({
      mobileNo: `+${this.props.signUpData?.mobileNo?.countryData?.callingCodes ?? "00"
        }${this.props.signUpData?.mobileNo.mobileNo}`
    });
    const status = checkError(this.props.resendOTP);
    if (status.isError) {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: status.errMsg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    } else {
      this.setState({
        resend: res?.payload?.data?.message,
        otp: "",
      });
    }
  };
  componentDidMount() { }

  componentWillUnmount() {
    if (!this.state.added) {
      this.props.DELETE_USER({
        username: getMobileNumber(this.props.signUpData?.mobileNo),
      });
    }
  }

  shouldComponentUpdate(props, state) {
    return true;
  }

  render() {
    const { appointmentDetails, addmmber, parent_id, classes } = this.props;
    return (
      <div id={`${parent_id}_addmember_otp_parent_div`}>
        <Container
          id={`${parent_id}_addmember_otp_parent_container`}
          key={"0"}
          style={{ padding: "16px 24px" }}
        >
          <Div
            style={addmmber ? qdmstyles.de_da__c_cd : {}}
            id={`${parent_id}_addmember_otp_title_parent_div`}
          >
            <H6
              id={`${parent_id}_addmember_otp_title_text`}
              key={"0"}
              name={
                appointmentDetails
                  ? "Add Member"
                  : this.props.signUpData?.existingPerson
                    ? "Sign Up as Existing Member"
                    : "Sign Up as New Member"
              }
              style={{
                fontSize: "20px",
                display: "",
                color: UIColor.primaryText.color,
                letterSpacing: 0,
                lineHeight: 1,
                margin: 0
              }}
              //!addmmber ? qdmstyles.dNMp : qdmstyles.dNMp1}
              className={"pc_semibold"}
            ></H6>
            {addmmber && (
              <Div key={"1"} id={`${parent_id}_addmember_otp_back_text_div`}>
                <H6
                  className="pc_medium"
                  onClick={() => this.props.nextSection("back")}
                  style={{
                    color: UIColor.primaryColor.color,
                    fontSize: "14px",
                    letterSpacing: 0,
                    margin: 0,
                    cursor: "pointer"
                  }}
                >
                  Back
                </H6>
                {/* <Button
                  id={`${parent_id}_addmember_otp_back_button`}
                  key={"0"}
                  className="pc_medium"
                  name={"Back"}
                  style={qdmstyles.b__ae_ba_fcfdfc}
                  onClick={() => this.props.nextSection("back")}
                ></Button> */}
              </Div>
            )}
          </Div>
          <Divider
            id={`${parent_id}_addmember_otp_title_bottom_divider`}
            key={"1"}
            style={{ margin: "16px 0px" }}
          ></Divider>

          <Div
            id={`${parent_id}_addmember_otp_memberlisting_parent_div`}
            key={"2"}
            className={"qdm"}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#fff",
              border: "1px solid #DEE5EC",
              borderRadius: "10px",
              borderStyle: "solid",
              padding: "10px"
            }}
          >
            <Div
              id={`${parent_id}_addmember_otp_memberlisting_sublevel_div`}
              key={"0"}
              className={"qdm"}
              style={qdmstyles.nilbd}
            >
              <Avatar
                id={`${parent_id}_addmember_otp_memberlisting_sublevel_avatar`}
                key={"0"}
                variant={"circle"}
                letter={"Avatar"}
                src={
                  this.props.signUpData?.image?.url ||
                  "https://i.pravatar.cc/150?img=65"
                }
                alt={"Image"}
                // style={qdmstyles.ddd_dc_c_ee_dbb}
                style={{
                  margin: "0px",
                  marginRight: "12px",
                  height: "40px",
                  width: "40px",
                }}
              ></Avatar>

              <Div
                key={"1"}
                id={`${parent_id}_addmember_otp_memberlisting_name`}
              >
                <H6
                  key={"0"}
                  name={`${this.props.signUpData?.firstName} ${this.props?.signUpData?.middleName || ""
                    } ${this.props.signUpData?.lastName || ""}`}
                  style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    color: UIColor.primaryText.color,
                  }}
                  className={"pc_medium"}
                />

                <Text
                  id={`${parent_id}_addmember_otp_memberlisting_discription`}
                  key={"1"}
                  name={`${this.getAge(this.props.signUpData?.dob)} ${this.props.signUpData?.gender
                    }, +${this.props.signUpData?.mobileNo?.countryData
                      ?.callingCodes ?? "00"
                    }${this.props.signUpData?.mobileNo?.mobileNo ?? "XXXXXXXXX"
                    }, ${this.props.signUpData?.emailId}`}
                  style={{ color: UIColor.tertiaryText.color, fontSize: "12px" }}
                  className={"pc_regular"}
                ></Text>
              </Div>
            </Div>

            {!addmmber && (
              <Icons
                id={`${parent_id}_addmember_otp_memberlisting_edit_icon`}
                key={"1"}
                fontIcon={"pencil"}
                ariaHidden={"true"}
                size={14}
                style={{ color: UIColor.primaryColor.color, cursor: "pointer" }}
                onClick={async () => {
                  // await this.props.DELETE_USER({
                  //   username: getMobileNumber(this.props.signUpData?.mobileNo),
                  // })
                  this.props.nextSection(
                    "addmemberform",
                    this.props.signUpData
                  );
                }}
              ></Icons>
            )}
          </Div>
          {addmmber && (
            <>
              <div
                id={`${parent_id}_addmember_otp_relationship_div`}
                style={{
                  fontSize: "12px",
                  color: UIColor.secondaryText.color,
                  lineHeight: 1,
                  marginTop: "24px"
                }}
                className="pc_regular"
              >
                What's your relationship with {this.props.signUpData?.firstName}{" "}
                ?{" "}
                <span
                  id={`${parent_id}_addmember_otp_relationship_star`}
                  style={{ color: "red" }}
                >
                  *
                </span>
              </div>
              <TapSelect
                id={`${parent_id}_addmember_otp_relationship_tapselect`}
                key={"4"}
                fullWidth={false}
                label={false}
                selectedBorderColor={UIColor.secondaryButtonColor.color} 
                selectedBackgroundColor={UIColor.secondaryButtonColor.color}
                selectedTextColor="#fff"
                borderColor={UIColor.differentiationBackground.color}
                backgroundColor={UIColor.differentiationBackground.color}
                textColor={UIColor.secondaryText.color}
                textSize="16"
                iconHeight="20"
                iconWidth="20"
                options={[
                  { value: "Father", label: "Father" },
                  { value: "Mother", label: "Mother" },
                  { value: "Brother", label: "Brother" },
                  { value: "Sister", label: "Sister" },
                  { value: "Others", label: "Others" },
                ]}
                value={{
                  value: this.state.genderValue,
                  label: this.state.genderValue,
                }}
                onChange={(e) => this.changeState("genderValue", e.value)}
                style={{ marginTop: "", paddingTop: "" }}
                defaultSelected={{
                  value: this.state.genderValue,
                  label: this.state.genderValue,
                }}
                inLineStyles={{
                  fontSize: "14px",
                  fontFamily: "'pc_regular' !important",
                }}
                className={classes.tapSelect}
              // style={qdmstyles.SREcn}
              ></TapSelect>

              {/* <TextInput
                key={'0'}
                type={'text'}
                name={'text'}
                variant={'outlined'}
                helperTextType={'error'}
                label={
                  <div style={qdmstyles.dda_e_dc_a_afcccbd1}>
                    Mail Id (optional)
                  </div>
                }
                autoFocus={false}
                autoComplete={'off'}
                search={false}
                borderColor={UIColor.lineBorderFill.color}
                elevation={'0'}
                placeholder={''}
                size={'medium'}
                value={this.state.firstNameValue}
                onChange={e =>
                  this.changeState('firstNameValue', e.target.value)
                }
                error={this.state?.error?.firstNameValue}
              ></TextInput> */}
              <br />
              {/* <TermsAndConditions
                id={`${parent_id}_addmember_otp_send_appointment_mailid_termsandconditions`}
                value={true}
                inLineStyles={{
                  fontSize: "1px",
                  marginTop: "-10px",
                  marginBottom: "28px",
                }}
                lable={
                  <span
                    id={`${parent_id}_addmember_otp_send_appointment_mailid_termsandconditions_inside_span`}
                    className={"pc_regular"}
                    style={{ fontSize: "12px", color: UIColor.secondaryText.color }}
                  >
                    Send appointment update and notification to this mail id
                  </span>
                }
              /> */}
            </>
          )}
          <Div
            key={"3"}
            id={`${parent_id}_addmember_otp_enterotpcard_parent_div`}
            className={"qdm"}
            style={qdmstyles.adff___ada_bfbec}
          >
            <Card
              id={`${parent_id}_addmember_otp_enterotp_card_section`}
              key={"0"}
              children={"Card"}
              style={{
                backgroundColor: UIColor.differentiationBackground.color,
                padding: "16px",
                boxShadow: "none"
              }}
            >
              <Text
                id={`${parent_id}_addmember_otp_enterotp_card_inside_title`}
                key={"0"}
                name={"OTP Verification"}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "16px",
                  color: UIColor.primaryText.color
                }}
                className={"pc_medium"}
              ></Text>
              <Divider
                id={`${parent_id}_addmember_otp_enterotp_card_title_bottom_divider`}
                style={{ backgroundColor: UIColor.lineBorderFill.color }}
              ></Divider>

              <Text
                key={"1"}
                id={`${parent_id}_addmember_otp_enterotp_card_subtitle`}
                name={"Please enter the OTP sent to"}
                style={{
                  "display": "flex",
                  "justifyContent": "center",
                  "color": UIColor.tertiaryText.color,
                  "marginTop": "16px",
                  "marginBottom": "2px",
                  "fontSize": "14px"
                }}
                className={"pc_regular"}
              ></Text>

              <H6
                id={`${parent_id}_addmember_otp_enterotp_card_mobilenumber_h6`}
                key={"2"}
                name={this.props.signUpData?.mobileNo?.mobileNo ?? "99******66"}
                style={{
                  "display": "flex",
                  "justifyContent": "center",
                  "fontSize": "14px",
                  "color": UIColor.secondaryText.color,
                  "fontWeight": "600"
                }}
              ></H6>

              <Div
                id={`${parent_id}_addmember_otp_enterotp_card_otpinput_parent_div`}
                key={"3"}
                className={"qdm"}
                style={qdmstyles.fabbd_b___dfb}
              >
                <OtpInput
                  id={`${parent_id}_addmember_otp_enterotp_card_otpinput`}
                  numberOfInputs={6}
                  focusTextColor={UIColor.secondaryColor.color}
                  focusBorderColor={UIColor.secondaryColor.color}
                  key={"0"}
                  style={{ backgroundColor: UIColor.differentiationBackground.color }}
                  value={this.state.otp}
                  onChange={(e) => this.changeState("otp", e)}
                  className={"pc_regular"}
                  // className={this.state.resend === "Invalid OTP" && "otpError"}
                ></OtpInput>
              </Div>

              <Text
                key={"4"}
                id={`${parent_id}_addmember_otp_enterotp_card_resendotp_text`}
                name={
                  <span
                    id={`${parent_id}_addmember_otp_enterotp_card_resendotp_inside_recive_span`}
                    style={{ color: UIColor.tertiaryText.color }}
                  >
                    Didn't receive OTP ?{" "}
                    <span
                      id={`${parent_id}_addmember_otp_enterotp_card_resendotp_inside_resend_span`}
                      style={{ fontWeight: "bold", cursor: "pointer", color: UIColor.primaryText.color }}
                      onClick={() => this.resendOTP()}
                    >
                      Resend OTP
                    </span>
                  </span>
                }
                style={{
                  "display": "flex",
                  "justifyContent": "center",
                  "marginTop": "24px",
                  "marginBottom": "16px",
                  "fontSize": "12px",
                  "color": UIColor.primaryText.color
                }}
                className={"pc_regular"}
              ></Text>
             
              {(this.state.resend || this.state?.error) && (
                <Text
                  id={`${parent_id}_addmember_otp_enterotp_card_resendotp_resended_message_text`}
                  name={
                    <span style={{
                      "display": "flex",
                      "justifyContent": "center",
                      "fontSize": "12px",
                      "marginBottom": "6px",
                      "color": this.state.resend ? UIColor.success.color : UIColor.error.color
                    }}>
                      {this.state.resend || this.state.error}
                    </span>
                  }
                  className={"pc_regular"}
                ></Text>
              )}
            </Card>
            <Button
              id={`${parent_id}_addmember_otp_enterotp_card_confirm_button`}
              key={"5"}
              type={"button"}
              variant={"outlined"}
              name={
                appointmentDetails
                  ? this.props.signIn?.loading ? "Adding Member" : "Confirm & Add Member"
                  : this.props.signIn?.loading ? "Signing Up" : "Confirm & Sign Up"
              }
              style={{
                "display": "flex",
                "justifyContent": "center",
                "alignItems": "center",
                "margin": "auto",
                "marginTop": "24px",
                "borderRadius": "8px",
                "padding": "8px 16px",
                "marginBottom": "10px",
                "backgroundColor": UIColor.primaryColor.color,
                "fontSize": "14px",
                "height": "40px",
                "border": "1px solid #01205C"
              }}
              onClick={this.handleSubmit}
              className={"pc_medium"}
            // onClick={() => this.props.nextSection("movenextscreen")}
            ></Button>
          </Div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  registerUsers: state?.authSlice?.registerUsers,
  signIn: state?.authSlice?.signIn,
  resendOTP: state?.authSlice?.resendOTP,
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
  guestUserInfo: state?.authSlice?.guestInfo,
});
AppointmentPageDrawerOtpSection.contextType = AlertContext;

export default withStyles(styles)(
  connect(mapStateToProps, actions)(withRouter(AppointmentPageDrawerOtpSection))
);
